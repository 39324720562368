import React from 'react'
import { MetaTags } from 'react-meta-tags'
import { useLocation } from 'react-router-dom'

import { metaTags } from '../js/metaTags'

import image from '../img/Landing/background.webp'

export const MetaTagsComponent = () => {
  const location = useLocation()
  const path = location.pathname
  const tag = metaTags.find(metaTag => metaTag.path === path)

  return (
    <MetaTags>
      <title>{(tag?.title) ? (tag?.title) : 'INVIDAR - Inventing Realities for Business Benefit'}</title>
      <meta name='description' content={tag?.description} />
      <meta name='title' content={tag?.title} />
      <meta name='image' content={image} />
      {/* FACEBOOK META TAGS */}
      <meta property='og:title' content='MyApp' />
      <meta property='og:image' content='path/to/image.jpg' />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://invidar.com/' />
      <meta property='og:title' content='INVIDAR' />
      <meta property='og:description' content={tag?.description} />
      <meta property='og:image' content={image} />
      {/* TWITTER META TAGS */}
      <meta property='twitter:card' content={image} />
      <meta property='twitter:url' content='https://invidar.com/' />
      <meta property='twitter:title' content='INVIDAR' />
      <meta property='twitter:description' content={tag?.description} />
      <meta property='twitter:image' content={image} />
    </MetaTags>
  )
}
