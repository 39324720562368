import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './css/index.css'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import {
  BrowserRouter as Router
} from 'react-router-dom'
import ScrollToTop from './Components/ScrollToTop'

import globalEs from './lang/es/global.json'
import globalEn from './lang/en/global.json'
import globalDe from './lang/de/global.json'
import globalFr from './lang/fr/global.json'

let lang = ''
if (window.localStorage.getItem('lang') === null) {
  window.localStorage.setItem('lang', window.navigator.language)
  lang = window.navigator.language
} else {
  lang = window.localStorage.getItem('lang')
}

i18next.init({
  interpolation: { escapeValue: false },
  lng: lang,
  resources: {
    es: {
      global: globalEs
    },
    en: {
      global: globalEn
    },
    fr: {
      global: globalFr
    },
    de: {
      global: globalDe
    }
  }
})

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <I18nextProvider i18n={i18next}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </I18nextProvider>, rootElement)
} else {
  ReactDOM.render(
    <I18nextProvider i18n={i18next}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </I18nextProvider>, rootElement)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
