import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import logo from '../img/logos/logo.svg'

export default function Footer () {
  const location = useLocation()
  const [t] = useTranslation('global')
  return (
    <footer id='footer' className={(location.pathname.includes('projects') ? 'bg-black' : 'bg-invidarDark') + ' text-black bottom-0 relative h-full w-full flex flex-col lg:flex-row lg:items-end lg:justify-between px-12 pb-4 lg:pb-10'} aria-labelledby='footer-heading'>
      <div className='hidden lg:flex font-bold w-2/12 text-white text-xl mt-8 md:mt-6 pb-6 md:pl-12 relative order-2 lg:order-1 min-w-max'>
        <div className='relative w-full'>
          <span className='border-invidarLight absolute -bottom-5 -ml-6 h-16 w-16 border-b-4 border-l-4' />
          <p className='w-44 font-thin strapline-mini'>{t('app.inventingrealities')}</p>
        </div>
      </div>
      <div className='order-2 lg:order-2'>
        <div className='flex flex-row w-full py-1'>
          <Link to='/terms' className='hover:text-invidarPrimary text-white w-full text-center mt-2'>Terms and Conditions</Link>
        </div>
        <p className='text-white text-xs mt-4 text-center'>{t('app.footer')}</p>
      </div>
      <div className='hidden lg:flex flex-row order-1 lg:order-3 md:items-end self-end mt-6 md:mt-0 md:mb-6'>
        <img className='svgWhite' alt='Invidar Logo' src={logo} />
      </div>
    </footer>
  )
}
