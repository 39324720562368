import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom'
import React, { useEffect } from 'react'
import { AppLayout } from './Pages/AppLayout'
import { Provider } from 'react-redux'
import { configureStore } from './js/redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import ReactGA from 'react-ga'

function App (state) {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const { store, persistor } = configureStore(state.initialState)
  ReactGA.initialize('G-2P9GPK2CDY')

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes>
          <Route path='/*' element={<AppLayout />} />
        </Routes>
      </PersistGate>
    </Provider>
  )
}

export default App
