
//  Milwaukee

// import milwaukee from '../img/projects/milwaukee.webp'
// import milwaukee1 from '../img/projects/milwaukee/milwaukee1.webp'
// import milwaukee2 from '../img/projects/milwaukee/milwaukee2.webp'
// import milwaukee3 from '../img/projects/milwaukee/milwaukee3.webp'
// import milwaukee4 from '../img/projects/milwaukee/milwaukee4.webp'
// import milwaukee5 from '../img/projects/milwaukee/milwaukee5.webp'
// import milwaukee6 from '../img/projects/milwaukee/milwaukee6.webp'

// Samsung
// import samsung1 from '../img/projects/samsung/samsung1.webp'
// import samsung2 from '../img/projects/samsung/samsung2.webp'
// import samsung3 from '../img/projects/samsung/samsung3.webp'
// import samsung4 from '../img/projects/samsung/samsung4.webp'
// import samsung5 from '../img/projects/samsung/samsung5.webp'
// import samsung6 from '../img/projects/samsung/samsung6.webp'
// import samsung7 from '../img/projects/samsung/samsung7.webp'

// LEGO
// import lego1 from '../img/projects/lego/lego1.webp'
// import lego2 from '../img/projects/lego/lego2.webp'
// import lego3 from '../img/projects/lego/lego3.webp'
// import lego4 from '../img/projects/lego/lego4.webp'

import virtualoffice from '../img/projects/virtualoffice.webp'
import botanyXR from '../img/projects/botanyXR.webp'
import kitchen from '../img/projects/kitchen.webp'
import carousel from '../img/projects/carousel.webp'
import comingsoon from '../img/projects/comingSoon.webp'

// TEST IMAGE

import { useTranslation } from 'react-i18next'
export const useProjects = () => {
  const [t] = useTranslation('global')
  return [
    // {
    //   title: 'milwaukee',
    //   name: 'Milwaukee HUB',
    //   href: 'https://thehub.milwaukeetool.eu/',
    //   subtitle: t('projects.milwaukee.subtitle'),
    //   description: t('projects.milwaukee.description'),
    //   challengeHeader: t('projects.milwaukee.challengeheader'),
    //   challengeParagraph1: t('projects.milwaukee.challengeparagraph1'),
    //   challengeParagraph2: t('projects.milwaukee.challengeparagraph2'),
    //   midQuote: t('projects.milwaukee.midquote'),
    //   solutionHeader: t('projects.milwaukee.solutionheader'),
    //   solutionParagraph1: t('projects.milwaukee.solutionparagraph1'),
    //   solutionParagraph2: t('projects.milwaukee.solutionparagraph2'),
    //   ctaHeader: t('projects.milwaukee.ctaheader'),
    //   ctaText: t('projects.milwaukee.ctatext'),
    //   defaultImg: milwaukee,
    //   bodyImg: milwaukee3,
    //   assets: [
    //     { type: 'video', src: 'https://player.vimeo.com/video/685847917' },
    //     { type: 'img', src: milwaukee1 },
    //     { type: 'img', src: milwaukee2 },
    //     { type: 'img', src: milwaukee4 },
    //     { type: 'img', src: milwaukee5 },
    //     { type: 'img', src: milwaukee6 }
    //   ]
    // },

    // {
    //   title: 'samsung',
    //   name: 'SAMSUNG LIFE UNSTOPPABLE',
    //   href: 'https://news.samsung.com/global/samsung-marks-a-new-era-of-innovation-with-virtual-experience-life-unstoppable',
    //   subtitle: t('projects.samsung.subtitle'),
    //   description: t('projects.samsung.description'),
    //   challengeHeader: t('projects.samsung.challengeheader'),
    //   challengeParagraph1: t('projects.samsung.challengeparagraph1'),
    //   challengeParagraph2: t('projects.samsung.challengeparagraph2'),
    //   midQuote: t('projects.samsung.midquote'),
    //   solutionHeader: t('projects.samsung.solutionheader'),
    //   solutionParagraph1: t('projects.samsung.solutionparagraph1'),
    //   solutionParagraph2: t('projects.samsung.solutionparagraph2'),
    //   ctaHeader: t('projects.samsung.ctaheader'),
    //   ctaText: t('projects.samsung.ctatext'),
    //   defaultImg: samsung1,
    //   bodyImg: samsung2,
    //   assets: [
    //     { type: 'video', src: 'https://player.vimeo.com/video/703289983' },
    //     { type: 'img', src: samsung3 },
    //     { type: 'img', src: samsung4 },
    //     { type: 'img', src: samsung5 },
    //     { type: 'img', src: samsung6 },
    //     { type: 'img', src: samsung7 }
    //   ]
    // },
    // {
    //   title: 'lego',
    //   name: 'LEGO TECHNIC: LAMBORGHINI Sián FKP 37',
    //   href: 'https://legotechnicsian.com/?icmp=LP-SHH-XL-Technic_HB_XL_Lamborghini_Sian-TH-TC-71MWRCMXSE',
    //   subtitle: t('projects.lego.subtitle'),
    //   description: t('projects.lego.description'),
    //   challengeHeader: t('projects.lego.challengeheader'),
    //   challengeParagraph1: t('projects.lego.challengeparagraph1'),
    //   challengeParagraph2: t('projects.lego.challengeparagraph2'),
    //   midQuote: t('projects.lego.midquote'),
    //   solutionHeader: t('projects.lego.solutionheader'),
    //   solutionParagraph1: t('projects.lego.solutionparagraph1'),
    //   solutionParagraph2: t('projects.lego.solutionparagraph2'),
    //   ctaHeader: t('projects.lego.ctaheader'),
    //   ctaText: t('projects.lego.ctatext'),
    //   defaultImg: lego1,
    //   bodyImg: lego2,
    //   assets: [
    //     { type: 'img', src: lego3 },
    //     { type: 'img', src: lego4 }
    //   ]
    // }
    {
      title: t('projects.virtualOffice.title'),
      slug: 'office',
      defaultImg: virtualoffice,
      iframe: 'https://connector.eagle3dstreaming.com/v5/invidar/Eagle_Labs/INVIDAR',
      description: t('projects.virtualOffice.description'),
      callToAction: t('projects.virtualOffice.cta'),
      assets: [
        { type: 'img', src: virtualoffice }
      ],
      tags: [
        'Desktop',
        'PixelStream',
        'UnrealEngine'
      ]
    },
    // {
    //   title: t('projects.virtualOffice.title') + ' Mobile',
    //   slug: 'office',
    //   defaultImg: virtualoffice,
    //   iframe: 'https://connector.eagle3dstreaming.com/v5/invidar/Eagle_Labs/INVIDAR',
    //   description: t('projects.virtualOffice.description'),
    //   callToAction: t('projects.virtualOffice.cta'),
    //   assets: [
    //     { type: 'img', src: virtualoffice }
    //   ],
    //   tags: [
    //     'Mobile',
    //     'PixelStream',
    //     'UnrealEngine'
    //   ]
    // },
    {
      title: t('projects.kitchenConfig.title'),
      slug: 'apartment',
      defaultImg: kitchen,
      iframe: 'https://connector.eagle3dstreaming.com/v5/invidar/Antler_Demo/INVIDAR',
      description: t('projects.kitchenConfig.description'),
      callToAction: t('projects.kitchenConfig.cta'),
      assets: [
        { type: 'img', src: kitchen }
      ],
      tags: [
        'Desktop',
        'PixelStream',
        'UnrealEngine'
      ]
    },
    {
      title: t('projects.botanyXR.title'),
      slug: 'botany',
      defaultImg: botanyXR,
      iframe: 'https://botanyxr.invidar.com',
      description: t('projects.botanyXR.description'),
      callToAction: t('projects.botanyXR.cta'),
      assets: [
        { type: 'img', src: botanyXR }
      ],
      tags: [
        'Desktop',
        'Mobile',
        'VR',
        'AR',
        'ThreeJS'
      ]
    },
    {
      title: t('projects.invidarCarousel.title'),
      slug: 'carousel',
      defaultImg: carousel,
      description: t('projects.invidarCarousel.description'),
      callToAction: t('projects.invidarCarousel.cta'),
      assets: [
        { type: 'img', src: carousel }
      ],
      tags: [
        'Desktop',
        'Mobile',
        'ThreeJS'
      ]
    },
    {
      title: t('projects.comingSoon.title'),
      defaultImg: comingsoon,
      description: t('projects.comingSoon.description'),
      callToAction: t('projects.comingSoon.cta'),
      assets: [
        { type: 'img', src: comingsoon }
      ],
      tags: [
        'Desktop',
        'Coming Soon'
      ]
    },
    {
      title: t('projects.moreDesktop.title'),
      defaultImg: comingsoon,
      description: t('projects.moreDesktop.description'),
      callToAction: t('projects.comingSoon.cta'),
      assets: [
        { type: 'img', src: comingsoon }
      ],
      tags: [
        'Mobile',
        'Coming Soon'
      ]
    }
  ]
}
