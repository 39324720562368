import React, { useEffect, useState } from 'react'
import { ContactMap } from '../Components/ContactMap'
import { ContactDetails } from '../Components/ContactDetails'
import { ContactForm } from '../Components/ContactForm'
import api from '../js/api'

export const Contact = ({ setShowContact }) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    async function fetchData () {
      const result = await api.Pages.contactUs()
      if (result.success) {
        setData(result.data)
      }
    }
    fetchData()
  }, [])
  return (
    <>
      <div className='content min-h-screen'>
        <div className='flex flex-wrap flex-col md:flex-row w-full h-full pt-32'>
          <div className='w-full lg:w-1/2 xl:w-7/12'>
            <ContactDetails title={data?.data.attributes.Title} description={data?.data.attributes.Description} address={data?.data.attributes.contactAddress} email={data?.data.attributes.contactEmail} />
          </div>
          <div className='w-full lg:w-1/2 xl:w-5/12 h-3/4 px-8 lg:pl-16 lg:pr-16 z-10'>
            <ContactForm title={data?.data.attributes.contactFormtitle} email={data?.data.attributes.contactFormEmailField} buttonSendText={data?.data.attributes.contactFormSendButton} message={data?.data.attributes.contactFormMessageField} name={data?.data.attributes.contactFormNameField} />
          </div>
          <div className='flex w-full h-60'>
            <ContactMap />
          </div>
        </div>
      </div>
    </>
  )
}
