import React from 'react'

// import background from '../img/about/background.webp'
import background from '../img/about/eaglelabs.png'
import ReactMarkdown from 'react-markdown'

export const AboutCover = ({ title, buttonText, description, setShowContact }) => {
  return (
    <div className='flex flex-col justify-end min-h-fit h-screen w-full about-cover' style={{ backgroundImage: `url(${background})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
      <div className='flex justify-end items-center h-full w-full'>
        <div className='filter flex justify-end items-center h-full w-full'>
          <div className='flex flex-col items-center lg:items-start w-full lg:w-11/12'>
            <div className='flex flex-col w-10/12 lg:w-6/12 pt-16 sm:py-32 space-y-10'>
              <h1 className='text-invidarPrimary uppercase text-2xl font-thin lg:ml-20 whitespace-pre-line'>{title}</h1>
              <p className='text-invidarLight text-md font-thin lg:ml-20'><ReactMarkdown>{description}</ReactMarkdown></p>
              <button onClick={setShowContact} className='invidar-about-button relative text-md font-thin border-2 border-invidarPrimary w-fit lg:ml-20'>
                <div className='invidar-about-button-bar absolute top-0 bottom-0 h-full bg-invidarPrimary' />
                <p className='mix-blend-difference text-white text-xl'>{buttonText}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
