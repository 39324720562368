import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AboutTeamCarouselMobile } from './AboutTeamCarouselMobile'
import { getImgFullURL } from '../js/helpers/imgHelper'

export const AboutTeam = ({ meetTheTeamTitle, team }) => {
  const [selectedWorker, setSelectedWorker] = useState(0)
  const [heightAbsoluteDivCarousel, setHeightAbsoluteDivCarousel] = useState(0)
  const location = useLocation()
  const workers = team?.data.map((worker) => {
    return { name: worker.attributes.Name, role: worker.attributes.PositionTitle, img: getImgFullURL(worker.attributes.Picture?.data.attributes.url), description: worker.attributes.Description }
  })

  const scroll = document?.getElementById('aboutScroll')
  const descriptionScroll = document?.getElementById('descriptionScroll')
  const description = document?.getElementById('description')
  const teamImage = document?.getElementById('teamImage0')
  const marginRightImage = 48
  useEffect(() => {
    if (scroll !== null) {
      scroll.scrollLeft = teamImage?.width * selectedWorker
      descriptionScroll.scrollLeft = description?.clientWidth * selectedWorker
    }
  }, [selectedWorker, scroll, description?.clientWidth, descriptionScroll, teamImage?.width])

  window.onresize = () => {
    scroll.scrollLeft = (teamImage?.clientWidth + marginRightImage) * selectedWorker
    descriptionScroll.scrollLeft = description?.clientWidth * selectedWorker
  }

  useEffect(() => {
    const scroll = document?.getElementById('aboutScroll')
    setTimeout(() => {
      scroll.scrollLeft = 0
    }, 200)
    if (scroll !== null) {
      scroll.scrollLeft = 0
    }
  }, [location.pathname])

  const descriptionContainerMobile = document.getElementsByClassName('descriptionContainerMobile')
  Array.prototype.forEach.call(descriptionContainerMobile, function (container) {
    if (heightAbsoluteDivCarousel < container.clientHeight) {
      setHeightAbsoluteDivCarousel(container.clientHeight)
    }
  })

  return (
    <div className='w-full h-full relative bg-invidarLight text-invidarDark transition-all select-none'>
      <div className='flex flex-col justify-end items-end'>
        <div className='bg-invidarLight flex flex-col relative w-full lg:w-11/12 pt-10 lg:pt-20 pb-10 lg:pr-20 lg:pb-0 z-10 items-center lg:items-start'>
          <h3 className='text-invidarPrimary uppercase font-thin text-lg w-10/12 lg:w-full lg:mx-20 mb-10'>{meetTheTeamTitle}</h3>
          {window.innerWidth > 640
            ? <>
              <div className='flex w-full h-full relative px-16 lg:px-0'>
                <svg onClick={() => (selectedWorker !== 0) ? (setSelectedWorker(prev => prev - 1), (window.innerWidth < 1600) ? scroll.scrollLeft -= 248 : scroll.scrollLeft -= 296) : (setSelectedWorker(workers.length - 1), scroll.scrollLeft = workers.length * 296)} className='absolute cursor-pointer transform rotate-180 left-4 md:-left-0 center-vertically fill-invidarDark hover:fill-invidarPrimary transition-all duration-200' xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 24 24'><path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z' /></svg>
                <div id='aboutScroll' className='w-full overflow-x-auto scrollStyle scroll-smooth flex flex-row items-center aboutContainer lg:mx-20'>
                  {workers?.map((worker, index) => {
                    return (
                      <img
                        id={`teamImage${index}`}
                        onClick={() => { setSelectedWorker(index); (index === 0) ? scroll.scrollLeft = 0 : (index === workers.length - 1) ? scroll.scrollLeft = 10000 : scroll.scrollLeft = 296 * selectedWorker }} className={((workers[selectedWorker]?.name === worker?.name) ? 'activeImage' : 'inactiveImage') +
                        ((index === workers.length - 1) ? ' mr-0 ' : ' ml-12 lg:ml-0 mr-12 ') + ' h-full cursor-pointer profileImg'}
                        key={index} alt='' src={worker.img}
                      />
                    )
                  })}
                </div>
                <svg onClick={() => (selectedWorker !== workers.length - 1) ? (setSelectedWorker(prev => prev + 1), (window.innerWidth < 1600) ? scroll.scrollLeft += 248 : scroll.scrollLeft += 296) : (setSelectedWorker(0), scroll.scrollLeft = 0)} className='absolute cursor-pointer right-4 md:right-0 center-vertically fill-invidarDark hover:fill-invidarPrimary transition-all duration-200' xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 24 24'><path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z' /></svg>
              </div>
              <div className='flex flex-col h-auto mx-20 my-8 w-full items-center lg:items-start'>
                <div id='descriptionScroll' style={{ touchAction: 'pan-y' }} className='w-10/12 mt-3 font-thin text-lg flex flex-row overflow-x-scroll scrollStyle scroll-smooth'>
                  {workers?.map((worker) => {
                    return (
                      <div id='description' key={worker.name} className='descriptionContainer w-full flex-grow pr-3 sm:pr-0 min-w-full'>
                        <div className='flex flex-col justify-start w-full lg:w-7/12 px-1 xl:px-0'>
                          <div className='w-full flex flex-col lg:flex-row uppercase text-lg md:text-xl mb-3'>
                            <h5 className='font-thin mr-3 text-invidarLightSecondary'>{worker?.name}: </h5>
                            <h5 className='font-thin text-invidarPrimary'>{worker?.role}</h5>
                          </div>

                          <p className='whitespace-pre-line'>{worker.description}</p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </>
            : <AboutTeamCarouselMobile workers={workers} />}
          <div className='absolute w-full bottom-16' style={{ height: heightAbsoluteDivCarousel }} />
        </div>
        <div className='lg:h-16' />
      </div>
    </div>

  )
}
