import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

export const ContactDetails = ({ email, address, title, description }) => {
  return (
    <div className='lg:min-h-[40vh] px-8 pb-8 lg:pl-32 lg:pr-0'>
      <div className='w-full h-full lg:p-8 text-white'>
        <h1 className='text-invidarPrimary text-2xl'>{title}</h1>
        <p className='pt-4'>{description}</p>
        <div className='text-lg pt-8 xl:pt-12 whitespace-pre-line flex flex-row'>
          <p className='mr-3'>📧</p>
          <a href={`mailto:${email}`}>
            <p>{email}</p>
          </a>
        </div>
        <div className='text-lg pt-4 whitespace-pre-line flex flex-row'>
          <p className='mr-3'>🏢</p>
          <p><ReactMarkdown>{address}</ReactMarkdown> </p>
        </div>
      </div>
    </div>
  )
}
