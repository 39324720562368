import React, { useEffect, useState } from 'react'
import api from '../js/api'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
export const Terms = () => {
  const [data, setData] = useState()
  useEffect(() => {
    api.Pages.terms().then((response) => {
      console.log(response)
      setData(response.data.data.attributes.content)
    })
  }, [])
  return (
    <div className='content flex flex-row w-screen mt-32 mb-16 px-8 justify-end'>
      <section className='rich-text py-6 text-white w-10/12 mx-auto'>
        <Markdown className={"terms"} remarkPlugins={[remarkGfm]}>
          {data}
        </Markdown>
      </section>
    </div>
  )
}
