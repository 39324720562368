import React from 'react'
import ReactMapboxGl from 'react-mapbox-gl'

import 'mapbox-gl/dist/mapbox-gl.css'

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiYWxleHRoYWtlIiwiYSI6ImNsN3QyNnlsZTAwOWgzcXFseG1tYnA1YWQifQ.9mLYIM61lgcSmXvNwuVOWA'
})

export const ContactMap = () => {
  return (
    <div className='contact-map absolute w-full bottom-0'>
      <Map
        // eslint-disable-next-line
        style='mapbox://styles/alexthake/cl7t2z0zh000z15mu90nqjw9k'
        center={[-1.906, 50.7235]}
        pitch={[60]}
        bearing={[60]}
        zoom={[17]}
        containerStyle={{
          height: '40vh',
          minWidth: '100%',
          zIndex: 0
        }}
      />
    </div>
  )
}
