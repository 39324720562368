export const metaTags = [
  { path: '/', title: 'INVIDAR - Inventing Realities for Business Benefit', description: "Specialists in real-time 3D solutions and immersive technology with our team of 3D modeler's, full stack, and real-time developers we can build your vision into a reality, invent reality build business." },
  { path: '/about', title: 'INVIDAR - About Us', description: 'INVIDAR was founded at the start of the COVID-19 pandemic, specialising in real time 3D solutions and immersive technology, with the objective to work on and later deliver industry leading projects.' },
  { path: '/demos', title: 'INVIDAR - Demos', description: 'See what our team is capable of yourself by enjoying our range of demo projects, from pixelstreamed environments to realtime browser experiences.' },
  { path: '/services', title: 'INVIDAR - Services', description: '3D Modelling, Real-Time, Development. We provide a full suite of services to create the software your business needs.' },
  { path: '/contact', title: 'INVIDAR - Contact Us', description: 'Get in touch with us today to discuss the project that will bring your business into the digital age.' },

  { path: '/modelling', title: 'INVIDAR - Modelling', description: '' },
  { path: '/realtime', title: 'INVIDAR - Real-Time', description: '' },
  { path: '/development', title: 'INVIDAR - Development', description: '' },

  { path: '/demos/office', title: 'INVIDAR - Virtual Office', description: '' },
  { path: '/demos/apartment', title: 'INVIDAR - Apartment Configurator', description: '' },
  { path: '/demos/botany', title: 'INVIDAR - BotanyXR', description: '' },
  { path: '/demos/carousel', title: 'INVIDAR - ThreeJS Carousel', description: '' }
]
