export const initialState = {
}

export function ProjectReducer (
  state = initialState,
  action
) {
  switch (action.type) {
    case 'PROJECT_VISITED': {
      return action.payload
    }
    default:
      return state
  }
}
