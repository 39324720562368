import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import $ from 'jquery'

export const ContactForm = ({ title, name, email, message, buttonSendText }) => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: ''
  })
  const emailRegex = /^[^@ ]+@[^@ ]+\.[^@ ]+$/
  const [errName, setErrName] = useState('')
  const [errEmail, setErrEmail] = useState('')
  const [errMessage, setErrMessage] = useState('')
  const [t] = useTranslation('global')

  function handleOnChange (e) {
    if (e.target.name === 'name') {
      setErrName('')
    } else if (e.target.name === 'email') {
      setErrEmail('')
    } else if (e.target.name === 'message') {
      setErrMessage('')
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  $(function () {
    const message = $('#contactmessage')
    const hiddenMessage = $('#hiddenMessage')
    message.on('input', function () {
      message.attr('value', message.html())
      hiddenMessage.attr('value', message.html())
      setErrMessage('')
      form.message = message.html()
    })
  })

  function verifyField () {
    const testEmail = emailRegex.test(form.email)
    console.log(Object.entries(form))
    Object.entries(form).forEach(value => {
      if (value[0] === 'name' && value[1].length <= 2) {
        setErrName(t('app.errname'))
      } else if (value[0] === 'email') {
        if (value[1].length <= 6) {
          setErrEmail(t('app.erremail'))
        } else if (!testEmail) {
          setErrEmail(t('app.erremailformat'))
        }
      } else if (value[0] === 'message' && value[1].length < 20) {
        setErrMessage(t('app.errmessage'))
      }
    })
  }

  function handleOnSubmit (e) {
    e.preventDefault()
    const formDiv = document.getElementById('contactform')
    verifyField()
    if ((errName === '' && form.name.length > 2) && (errEmail === '' && emailRegex.test(form.email)) && (form.message.length > 20)) {
      formDiv.action = 'https://formspree.io/f/mgedpzqg'
      formDiv.submit()
    } else {
      console.warn('Message not sent')
    }
  }

  return (
    <div className='relative mb-0 lg:mb-60'>
      <div className='contactform-wrapper bg-invidarLight w-full h-full px-4 py-8 sm:p-8 lg:absolute'>
        <form id='contactform' method='POST' onSubmit={handleOnSubmit} className=''>
          <h2 className='text-invidarPrimary text-2xl pb-6'>{title}</h2>
          <div className='flex flex-col relative mt-12'>
            <input className='contactinput order-2 mt-1 py-1 bg-transparent border-invidarDark border-b-2' required onChange={handleOnChange} placeholder=' ' value={form.name} type='text' name='name' id='contactname' />
            <label className='contactlabel order-1 pointer-events-none' htmlFor='#contactname'>{name}</label>
            {(errName !== '') && <label className='text-invidarPrimary mt-2'>{errName}</label>}
          </div>
          <div className='flex flex-col relative mt-16'>
            <input className='contactinput order-2 mt-1 py-1 bg-transparent border-invidarDark border-b-2' required onChange={handleOnChange} placeholder=' ' value={form.email} type='text' name='email' id='contactemail' />
            <label className='contactlabel order-1 pointer-events-none' htmlFor='#contactemail'>{email}</label>
            {(errEmail !== '') && <label className='text-invidarPrimary mt-2'>{errEmail}</label>}
          </div>
          <div className='flex flex-col relative mt-16'>
            <span className='contactspaninput order-2 relative w-full input block min-h-12 mt-1 py-1 bg-transparent border-invidarDark border-b-2 pt-2 resize-y outline-0' role='textbox' contentEditable='true' required onChange={handleOnChange} placeholder=' ' value={form.message} type='text' id='contactmessage' />
            <input className='contacthiddeninput hidden' id='hiddenMessage' type='text' name='message' placeholder=' ' />
            <label className='contactlabel order-1 pointer-events-none' htmlFor='#contactmessage'>{message}</label>
            {(errMessage !== '') && <label className='text-invidarPrimary mt-2'>{errMessage}</label>}
          </div>
          <div className='flex mt-10 justify-start text-lg'>
            <button className='invidar-about-button relative text-md font-thin border-2 border-invidarPrimary w-fit'>
              <div className='invidar-about-button-bar absolute top-0 bottom-0 h-full bg-invidarLight' />
              <p className='mix-blend-difference text-invidarLight text-xl'>{buttonSendText}</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
