import React from 'react'

import { AboutTeam } from './AboutTeam'
import { AboutCover } from './AboutCover'
import { AboutClients } from './AboutClients'

import { AboutContactSection } from './AboutContactSection'

export const AboutPartners = ({ data, setShowContact }) => {
  let clients, team, pageData
  if (data) {
    pageData = data.data.attributes
    console.log(pageData)
    clients = pageData.companies_work_withs
    team = pageData.employees
  }
  return (
    <div className='flex flex-col bg-white relative'>
      <AboutCover title={pageData?.Title} description={pageData?.Description} buttonText={pageData?.ContactButtonText} setShowContact={setShowContact} />
      <AboutClients workWithTitle={pageData?.WhoWorkWithTitle} clients={clients} />
      <AboutTeam meetTheTeamTitle={pageData?.meetTheTeamTitle} team={team} />
      <AboutContactSection title={pageData?.OurSolutionTitle} description={pageData?.OurSolutionDescription} contactButtonText={pageData?.OurSolutionTextButton} setShowContact={setShowContact} />
    </div>
  )
}
