import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

export const AboutTeamCarouselMobile = React.memo(function AboutTeamCarouselMobile ({ workers }) {
  return (
    <Carousel showStatus={false} showThumbs={false} showIndicators={false} infiniteLoop swipeScrollTolerance={40} preventMovementUntilSwipeScrollTolerance className='aboutCarousel relative w-screen'>
      {workers.map((worker) => {
        return (
          <div className='cursor-pointer relative' key={worker}>
            <img
              className='aboutCarouselImg'
              alt={worker.name}
              src={worker.img}
            />
            <div key={worker.name} className='descriptionContainerMobile w-full flex-grow relative mt-8 text-left px-9'>
              <div className='w-full flex flex-col lg:flex-row uppercase text-lg md:text-xl my-3'>
                <h5 className='font-thin mr-3'>{worker?.name}: </h5>
                <h5 className='font-thin text-invidarPrimary'>{worker?.role}</h5>
              </div>
              <p className=''>{worker.description}</p>
            </div>
          </div>
        )
      })}
    </Carousel>
  )
})
