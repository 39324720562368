import React from 'react'

export const Privacy = ({ setShowContact }) => {
  return (
    <div className='content flex flex-row w-screen mt-32 mb-16 px-8 justify-end'>
      <div className='w-11/12 pr-10'>
        <div className='w-10/12 text-white'>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sagittis felis ante, ac maximus urna suscipit sit amet. Integer tempor rhoncus nibh non convallis. Aenean quam diam, lacinia quis metus at, posuere ornare ipsum. Aenean et blandit elit, a bibendum dolor. Quisque et diam enim. In ac fermentum massa. Sed dapibus erat elit, a rhoncus eros dapibus sed. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean semper sapien ut velit ullamcorper, ac porta erat fringilla. Nulla tempor, nibh vel imperdiet posuere, tellus velit ullamcorper quam, vestibulum dapibus orci nibh id neque. Maecenas eu ornare augue. Curabitur lobortis, leo ac porta egestas, lacus ligula consequat felis, eget rutrum ante enim in ex.</p>
          <p>Nunc ultrices bibendum semper. Duis hendrerit, nisi in mollis faucibus, nulla odio interdum quam, non dignissim odio eros nec sem. Integer nec diam volutpat nulla posuere interdum rhoncus in nibh. Nullam non luctus risus. Duis eu enim maximus, malesuada neque non, viverra nisi. In vulputate congue pulvinar. Mauris ut ultricies tellus. Proin rhoncus dapibus nisl in vestibulum. Vivamus cursus quis dolor ac facilisis. Nam non condimentum nulla, ac eleifend nibh.</p>
          <p>In sit amet augue massa. Nullam sed sollicitudin arcu. Cras sit amet tincidunt magna. Aenean sed ex eu metus gravida egestas eget at massa. Integer semper nulla vel convallis bibendum. Praesent eu nisi quis nunc lacinia aliquam. Nulla posuere ullamcorper pellentesque. Cras felis ante, posuere vitae mi quis, fringilla posuere enim. Pellentesque posuere semper turpis, et luctus elit molestie eget. Fusce varius urna vel metus hendrerit malesuada. Vestibulum id magna velit. Sed eget massa rhoncus, sodales sem nec, suscipit elit. Sed laoreet sollicitudin est, vel pulvinar nisi viverra ac. Maecenas euismod tellus eget sem fringilla, sit amet tincidunt sapien gravida. Morbi maximus imperdiet convallis.</p>
          <p>Sed elementum felis euismod magna tincidunt congue ac ut elit. Maecenas suscipit rhoncus arcu, suscipit aliquet ex eleifend tempus. Pellentesque eget elit luctus, congue quam eget, lacinia velit. Sed convallis blandit ipsum nec vulputate. Nulla in lacus pulvinar, vulputate eros sit amet, rhoncus neque. Sed sollicitudin condimentum felis, a tempus risus facilisis sit amet. In mattis massa non diam iaculis, id mattis mauris vulputate. Ut maximus placerat metus et porttitor. Praesent rutrum tempor convallis. Ut sit amet porttitor elit, quis gravida mi. Integer vitae lacinia ex. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed consequat vitae eros ac dignissim.</p>
          <p>Integer egestas auctor magna vitae aliquet. Sed justo justo, euismod a elit a, gravida pretium nisl. Fusce vehicula ligula placerat accumsan eleifend. Cras varius tristique viverra. Vivamus ullamcorper mauris a orci porttitor dapibus et efficitur neque. Donec gravida, magna at laoreet interdum, mi risus vulputate quam, non cursus lectus sapien ac velit. Suspendisse potenti. Vivamus convallis augue sit amet ligula faucibus interdum id sit amet ex. Duis porta rhoncus ligula ut elementum. Cras aliquet tortor id accumsan congue. In et interdum neque. Vestibulum magna nunc, laoreet eu sapien id, aliquam eleifend nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p>Praesent aliquam euismod massa, id cursus arcu tempus quis. Etiam egestas metus non turpis efficitur dapibus. Curabitur tristique orci nec tincidunt egestas. Ut vulputate massa eu diam bibendum, eget lobortis nibh dapibus. Vestibulum efficitur ornare leo, id hendrerit arcu rutrum vitae. Suspendisse potenti. Duis eleifend rhoncus orci ut ultrices. Donec eu nisi at magna tincidunt tincidunt. Morbi eget dolor tempus, scelerisque magna eget, tristique ante.</p>
          <p>Vivamus accumsan sagittis ipsum id sollicitudin. Vestibulum in mauris mauris. Pellentesque venenatis tristique sapien. Aenean eget nisl id ipsum mattis faucibus. Donec iaculis mauris eget nulla porttitor aliquam. Integer maximus, lacus eget feugiat volutpat, neque ipsum semper turpis, quis interdum lorem turpis vitae tellus. Fusce at turpis augue. Nullam bibendum elit faucibus, scelerisque odio ut, aliquam felis. Quisque sem erat, cursus a nisi nec, eleifend luctus libero. Nunc nec molestie ex. Curabitur a tortor sit amet eros porttitor interdum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam consequat magna quis porttitor venenatis. Duis efficitur metus sit amet odio elementum elementum eu eu risus. Integer eu facilisis risus. In auctor consequat malesuada.</p>
          <p>Donec vitae sem pretium, fermentum sem eu, elementum felis. Sed ligula erat, elementum nec placerat a, rhoncus sed quam. Quisque tempus eget odio in lobortis. Aenean porta porttitor consectetur. In non accumsan enim. Curabitur elementum efficitur dolor, et sodales massa posuere eget. Vivamus leo felis, mollis ut ligula in, vehicula vehicula diam. Nam tempor purus non maximus malesuada. Nunc non aliquet ex. Nulla aliquet in risus vitae lacinia. Donec quis nibh sed risus consectetur sagittis vitae ac massa. Proin efficitur luctus porttitor. Duis at lacus in nisi consectetur facilisis in non massa. Sed a convallis nibh.</p>
          <p>Pellentesque porta pellentesque orci a molestie. Integer lacinia dapibus nulla id pharetra. Nam rutrum elit id neque ornare pretium in id diam. Fusce nisi velit, sollicitudin tempus dui vel, sodales sollicitudin ante. Phasellus vel sapien dui. Proin ut tellus at est tristique sodales vitae et tellus. Quisque condimentum, nisl at consequat condimentum, purus dolor convallis orci, ac malesuada justo magna a dolor. Proin sit amet porttitor tellus, ut sodales velit. Aliquam erat volutpat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur a mi hendrerit, aliquam diam in, gravida enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus sollicitudin diam, ut lacinia erat pulvinar in. Mauris sodales et libero quis pretium. Vivamus non dignissim massa, ultrices vulputate eros.</p>
          <p>Mauris dignissim elit a ligula mollis, vel gravida nunc ultrices. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aenean suscipit eros est, ut faucibus ligula sodales at. Nullam a fringilla mauris. Duis faucibus magna dui, nec cursus dolor semper ac. Morbi consequat accumsan leo nec porttitor. Duis pellentesque ex at arcu euismod, sed fermentum urna fringilla. Sed quam eros, facilisis ut sagittis ut, laoreet et lacus. Nunc pellentesque, ante non commodo aliquet, est velit tristique urna, sed cursus leo enim non erat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla posuere tincidunt lacus tincidunt lacinia. Sed posuere eleifend mi.</p>
        </div>
      </div>
    </div>
  )
}
