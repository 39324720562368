import axios from 'axios'

export const apiUrl = 'https://backend.invidar.com'
export const apiPort = ''
export const baseUrl = apiUrl + apiPort || 'http://localhost:1337'

const getHeaders = (Language) => {
  return {

  }
}

const apiGet = async (url, params, language = '') => {
  return axios.get(`${baseUrl}${url}`, { headers: getHeaders(language) })
    .then(response => ({ success: true, error: null, data: response.data })
    )
    .catch(response => ({ success: false, error: response, data: null })
    )
}

const apiPost = async (url, payload, language = '') => {
  return axios.post(`${baseUrl}${url}`, payload, { headers: getHeaders(language) })
    .then(response => ({
      success: true,
      error: null,
      data: response.data
    }))
    .catch(response => ({ success: false, error: response.json, data: null })

    )
}

const apiPatch = async (url, payload, language = '') => {
  return axios.patch(`${baseUrl}${url}`, payload, { headers: getHeaders(language) })
    .then(response => ({
      success: true,
      error: null,
      data: response.data
    }))
    .catch(response => ({ success: false, error: response.json, data: null })
    )
}
const apiDelete = async (url, payload, language = '') => {
  return axios.delete(`${baseUrl}${url}`, payload, { headers: getHeaders(language) })
    .then(response => ({
      success: true,
      error: null,
      data: response.data
    }))
    .catch(response => ({ success: false, error: response.json, data: null })
    )
}
const api = {
  Pages: {
    aboutUs: async () => apiGet('/api/about-us-page/?&populate[0]=companies_work_withs.logo&populate[1]=employees.Picture'),
    contactUs: async () => apiGet('/api/contact-us-page'),
    terms: async () => apiGet('/api/terms-and-conditions-page')
  }
}

export default api
