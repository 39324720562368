import { createStore } from 'redux'
import { persistStore } from 'redux-persist'
import persistReducer from 'redux-persist/es/persistReducer'
import { ProjectReducer } from './reducers/ProjectReducer'
import storage from 'redux-persist/lib/storage'

export const configureStore = (initialState) => {
  const persistConfig = {
    key: 'root',
    storage
  }

  const persistedReducer = persistReducer(persistConfig, ProjectReducer)
  const store = createStore(persistedReducer)
  const persistor = persistStore(store)
  return { store, persistor }
}
