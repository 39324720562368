import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import gsap from 'gsap'

export const ContactPopUp = (props) => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: ''
  })
  const emailRegex = /^[^@ ]+@[^@ ]+\.[^@ ]+$/
  const [errName, setErrName] = useState('')
  const [errEmail, setErrEmail] = useState('')
  const [errMessage, setErrMessage] = useState('')
  const [t] = useTranslation('global')
  const socialMedia = [
    { title: 'Linkedin', href: 'https://www.linkedin.com/company/invidar/', element: <svg className='icon mb-1' xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 24 24'><path d='M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z' /></svg> },
    { title: 'Twitter', type: 'link', href: 'https://twitter.com/INVIDAR_UK', element: <svg className='icon' xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 24 24'><path d='M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z' /></svg> }
  ]

  function handleOnChange (e) {
    if (e.target.name === 'name') {
      setErrName('')
    } else if (e.target.name === 'email') {
      setErrEmail('')
    } else if (e.target.name === 'message') {
      setErrMessage('')
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  function verifyField () {
    const testEmail = emailRegex.test(form.email)
    Object.entries(form).forEach(value => {
      if (value[0] === 'name' && value[1].length <= 2) {
        setErrName(t('app.errname'))
      } else if (value[0] === 'email') {
        if (value[1].length <= 6) {
          setErrEmail(t('app.erremail'))
        } else if (!testEmail) {
          setErrEmail(t('app.erremailformat'))
        }
      } else if (value[0] === 'message' && value[1].length < 20) {
        setErrMessage(t('app.errmessage'))
      }
    })
  }

  function handleOnSubmit (e) {
    e.preventDefault()
    const formDiv = document.getElementById('form')
    verifyField()
    if ((errName === '' && form.name.length > 2) && (errEmail === '' && emailRegex.test(form.email)) && (errMessage === '' && form.message.length > 20)) {
      formDiv.action = 'https://formspree.io/f/mgedpzqg'
      formDiv.submit()
    }
  }
  useEffect(() => {
    if (props.showContact) {
      gsap.to('#socialMedia',
        {
          opacity: 1, zIndex: 150, duration: 1
        })
    } else {
      gsap.to('#socialMedia',
        {
          opacity: 0, zIndex: 101, duration: 1
        })
      setTimeout(() => {
        gsap.to('#socialMedia',
          {
            opacity: 0, zIndex: 0, duration: 0.001
          })
      }, 1000)
    }
  }, [props.showContact])

  return (
    <div id='socialMedia' className='fixed w-full h-full flex flex-col justify-center items-center opacity-0 z-0' style={{ backgroundColor: 'rgba(0,0,0,0.9)' }}>
      <div onClick={() => props.setShowContact(false)} id='closeSocialMedia' className='absolute w-full h-full z-10' />
      <form id='form' method='POST' onSubmit={handleOnSubmit} className='z-20 bg-invidarDark w-10/12 sm:w-7/12 lg:w-5/12 xl:w-3/12 flex flex-col p-6'>
        <div className='flex flex-row justify-between items-center bg-invidarDark relative'>
          <h5 className='uppercase text-white text-2xl mb-2'>{t('app.contactus')}</h5>
          <div className='flex flex-row items-center'>
            {socialMedia.map((logo, index) => {
              return <a href={logo.href} aria-label={logo.title} target='_blank' className='ml-4 mb-4 cursor-pointer' key={index} rel='noreferrer'>{logo.element}</a>
            })}
          </div>
        </div>
        <div className='flex flex-col mt-2'>
          <input className='rounded pl-3 py-1' required onChange={handleOnChange} value={form.name} autoFocus placeholder={t('app.introducename')} type='text' name='name' id='name' />
          {(errName !== '') && <label className='text-invidarPrimary mt-2'>{errName}</label>}
        </div>
        <div className='flex flex-col mt-2'>
          <input className='rounded mt-1 pl-3 py-1' required onChange={handleOnChange} value={form.email} autoFocus placeholder={t('app.introduceemail')} type='text' name='email' id='email' />
          {(errEmail !== '') && <label className='text-invidarPrimary mt-2'>{errEmail}</label>}
        </div>
        <div className='flex flex-col mt-2'>
          <textarea className='rounded mt-1 pl-3 py-1 pt-2 resize-y h-20' required onChange={handleOnChange} value={form.message} placeholder={t('app.introducemessage')} type='text' name='message' id='message' />
          {(errMessage !== '') && <label className='text-invidarPrimary mt-2'>{errMessage}</label>}
        </div>
        <div className='flex mt-4 justify-end text-white text-lg'>
          <button className='rounded border border-white px-6 py-1 hover:bg-white hover:text-invidarDark uppercase transition-all duration-300'>{t('app.contact')}</button>
        </div>
      </form>
    </div>
  )
}
