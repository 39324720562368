import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import modelling from '../img/Landing/modelling.png'
import development from '../img/Landing/development.png'
import realtime from '../img/Landing/realtime.png'
import modellingBackground from '../img/services/modellingbg.webp'
import developmentBackground from '../img/services/developmentbg.webp'
import realtimeBackground from '../img/services/realtimebg.webp'

export const ServicesLayout = ({ setShowContact }) => {
  const [t] = useTranslation('global')

  return (
    <>
      <div className='flex flex-col md:flex-row flex-wrap bg-invidarDark h-screen overflow-x-hidden overflow-y-hidden pt-28 lg:pt-0'>
        <div className='service-segment relative grow transition-all duration-500 w-full h-1/6 hover:h-4/6 md:h-full md:hover:h-full md:w-1/12 md:hover:w-4/12 ease-in-out'>
          <div className='relative flex w-screen h-full md:w-full md:h-screen bg-center bg-cover' style={{ backgroundImage: `url(${modellingBackground})` }} />
          <div className='relative flex w-screen h-full md:w-full md:h-screen service-content-overlay' />
          <div className='service-content-details text-white w-9/12'>
            <img className='service-content-title' alt='modelling' src={modelling} />
            <p className='service-content-text text-md font-thin overflow-hidden text-lg'>{t('services.modelling.intro')}</p>
            <Link to='/modelling'>
              <div className='service-content-button relative text-md font-thin border-2 border-invidarPrimary'>
                <div className='service-content-button-bar absolute top-0 bottom-0 bg-invidarPrimary' />
                <p className='mix-blend-difference'>Explore</p>
              </div>
            </Link>
          </div>
        </div>
        <div className='service-segment relative grow transition-all duration-500 w-full h-1/6 hover:h-4/6 md:h-full md:hover:h-full md:w-1/12 md:hover:w-4/12 ease-in-out'>
          <div className='relative flex w-screen h-full md:w-full md:h-screen bg-center bg-cover' style={{ backgroundImage: `url(${realtimeBackground})` }} />
          <div className='relative flex w-screen h-full md:w-full md:h-screen service-content-overlay' />
          <div className='service-content-details text-white w-9/12'>
            <img className='service-content-title' alt='realtime' src={realtime} />
            <p className='service-content-text text-md font-thin overflow-hidden text-lg'>{t('services.realtime.intro')}</p>
            <Link to='/realtime'>
              <div className='service-content-button relative text-md font-thin border-2 border-invidarPrimary'>
                <div className='service-content-button-bar absolute top-0 bottom-0 bg-invidarPrimary' />
                <p className='mix-blend-difference'>Explore</p>
              </div>
            </Link>
          </div>
        </div>
        <div className='service-segment relative grow transition-all duration-500 w-full h-1/6 hover:h-4/6 md:h-full md:hover:h-full md:w-1/12 md:hover:w-4/12 ease-in-out'>
          <div className='relative flex w-screen h-full md:w-full md:h-screen bg-center bg-cover' style={{ backgroundImage: `url(${developmentBackground})` }} />
          <div className='relative flex w-screen h-full md:w-full md:h-screen service-content-overlay' />
          <div className='service-content-details text-white h-full w-full md:w-9/12'>
            <img className='service-content-title' alt='development' src={development} />
            <p className='service-content-text text-md font-thin overflow-hidden text-lg'>{t('services.development.intro')}</p>
            <Link to='/development'>
              <div className='service-content-button relative text-md font-thin border-2 border-invidarPrimary'>
                <div className='service-content-button-bar absolute top-0 bottom-0 bg-invidarPrimary' />
                <p className='mix-blend-difference'>Explore</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
