import React from 'react'

import teamFooter from '../img/about/team_footer2.webp'
import ReactMarkdown from 'react-markdown'

export const AboutContactSection = ({title,description,contactButtonText, setShowContact }) => {
  return (
    <div className='flex flex-col w-full items-end justify-center lg:justify-end bg-invidarDark'>
      <div className='flex flex-col w-full py-12 lg:py-24 lg:w-11/12 items-center lg:items-start'>
        <div className='w-10/12 lg:w-6/12'>
          <h3 className='text-invidarPrimary uppercase font-semibold w-full mb-8 lg:ml-20'>{title}</h3>
          <div className='flex flex-col space-y-10 w-full justify-self-center lg:ml-20'>
            <p className='text-invidarLight text-md font-thin whitespace-pre-line'><ReactMarkdown>{description}</ReactMarkdown></p>
            <button onClick={setShowContact} className='invidar-about-button relative text-md font-thin border-2 border-invidarPrimary w-fit'>
              <div className='invidar-about-button-bar absolute top-0 bottom-0 h-full bg-invidarPrimary' />
              <p className='mix-blend-difference text-white text-xl'>{contactButtonText}</p>
            </button>
          </div>
        </div>
      </div>
      <div className='team-footer w-full bg-cover bg-center' style={{ backgroundImage: `url(${teamFooter})` }} />
    </div>
  )
}
