import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import Background from '../img/Landing/background.png'
// import BackgroundLogo from '../img/Landing/invidarBackground.png'
import loadGif from '../img/Landing/invidar.gif'
import binary0 from '../img/Landing/binary0.png'
import binary1 from '../img/Landing/binary1.png'
import binary2 from '../img/Landing/binary2.png'
import splat from '../img/Landing/splat.png'
import atlas from '../ThreeJS/models/atlas.png'
import carouselGlb from '../ThreeJS/models/Invidar_Carousel.glb'
import penguinGlb from '../ThreeJS/models/Penguin.glb'

// Jquery
import $ from 'jquery'
// eslint-disable-next-line no-unused-vars
import terminal from 'jquery.terminal'

// Vimeo
import Player from '@vimeo/player'

// ThreeJS
import * as THREE from 'three'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { DecalGeometry } from 'three/examples/jsm/geometries/DecalGeometry.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
// import * as dat from 'lil-gui'
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js'
// import typefaceFont from 'three/examples/fonts/helvetiker_regular.typeface.json'

// Shaders
import portalVertexShader from '../ThreeJS/shaders/portal/vertex.glsl'
import portalFragmentShader from '../ThreeJS/shaders/portal/fragment.glsl'

// GSAP
import { gsap } from 'gsap'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { isBrowser, isDesktop } from 'react-device-detect'
import { useLocation } from 'react-router-dom'

gsap.registerPlugin(MotionPathPlugin)

window.$ = $

let initialised = false

export const InvidarCarousel = (props) => {
  const [t] = useTranslation('global')
  const [height, setHeight] = useState(window.visualViewport.height)
  const [width, setWidth] = useState(window.visualViewport.width)
  const location = useLocation()

  const changeSize = () => {
    if (!isBrowser) {
      if (window.matchMedia('(orientation: landscape)').matches) {
        setHeight(window.visualViewport.height)
        setWidth(window.visualViewport.width)
      } else {
        setHeight(window.visualViewport.height)
        setWidth(window.screen.width)
      }
    } else {
      setHeight(window.visualViewport.height)
      setWidth(window.visualViewport.width)
    }
  }

  useEffect(() => {
    changeSize()
  }, [])

  window.addEventListener('resize', () => {
    setTimeout(() => {
      changeSize()
    }, 250)
  })

  useEffect(() => {
    initialised = false
  }, [location.pathname])

  useEffect(() => {
    if (initialised === false) {
      initialised = true
      /**
       * Base
       */
      // // Debug
      // const debugObject = {}
      // const gui = new dat.GUI({
      //   width: 400
      // })

      // Canvas
      const canvas = document.querySelector('canvas.webgl')
      const container = document.querySelector('div.cssContainer')

      // Scene
      const scene = new THREE.Scene()
      const cssScene = new THREE.Scene()

      /**
       * HTML Elements
       */
      const invidarButton = document.getElementById('invidarButton')
      const modelButton = document.getElementById('modelButton')
      const devButton = document.getElementById('devButton')
      const realtimeButton = document.getElementById('realtimeButton')
      const tagline = document.getElementById('tagline')
      const loadingGif = document.getElementById('invidarLoading')

      const htmlArray = [invidarButton, modelButton, devButton, realtimeButton, tagline]

      /**
       * Loading Screen
       */
      const overlayGeometry = new THREE.PlaneBufferGeometry(2, 2, 1, 1)
      const overlayMaterial = new THREE.ShaderMaterial({
        transparent: true,
        uniforms: {
          uAlpha: { value: 1 }
        },
        vertexShader: `
          void main()
          {
            gl_Position = vec4(position, 1.0);
          }
        `,
        fragmentShader: `
          uniform float uAlpha;

          void main()
          {
            gl_FragColor = vec4(0.118, 0.118, 0.118, uAlpha);
          }
        `
      })
      const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
      overlay.position.y = 4
      scene.add(overlay)

      /**
       * Loaders
       */
      // Loading Manager
      let busy = true
      const loadingManager = new THREE.LoadingManager(
        () => {
          showreel.visible = true
          gsap.to(overlayMaterial.uniforms.uAlpha, {
            value: 0,
            duration: 1.75,
            ease: 'power3.in',
            onComplete () {
              scene.remove(overlay)
              busy = false
            }
          })
          const loadingGifValues = {}
          loadingGifValues.opacity = 1
          gsap.to(loadingGifValues, {
            opacity: 0,
            ease: 'power1.in',
            duration: 1,
            onUpdate () {
              loadingGif.style.opacity = loadingGifValues.opacity
            }
          })
          fadeHud(true)
        },
        () => {
          // console.log('progress')
        }

      )

      // Texture loader
      const textureLoader = new THREE.TextureLoader(loadingManager)

      // Draco loader
      const dracoLoader = new DRACOLoader(loadingManager)
      dracoLoader.setDecoderPath('draco/')

      // GLTF loader
      const gltfLoader = new GLTFLoader(loadingManager)
      gltfLoader.setDRACOLoader(dracoLoader)

      // Font Loader
      const fontLoader = new FontLoader()
      let menlo
      const hintObjects = new Map()
      fontLoader.load('fonts/helvetiker_regular.typeface.json', (font) => {
        menlo = font
        /**
         * Hints
         */
        const hints = [
          { id: 'tv_buttons', text: 'Click to Interact', size: 0.1, position: new THREE.Vector3(-1.175, 1.375, -0.1), rotation: 180, color: 0x222222 }
          // { id: 'model_buttons', text: 'Click to Interact', size: 0.1, position: new THREE.Vector3(-0.1, 1.8, 0.75), rotation: 270, color: 0x000000 }
        ]
        hints.forEach(function (hint) {
          if (isDesktop) {
            const hintTextGeometry = new TextGeometry(hint.text, {
              font: menlo,
              size: hint.size,
              height: 0.01,
              curveSegments: 6,
              bevelEnabled: true,
              bevelThickness: 0.005,
              bevelSize: 0.005,
              bevelOffset: 0,
              bevelSegments: 2
            })
            const hintTextMaterial = new THREE.MeshStandardMaterial({ color: hint.color, transparent: true, opacity: 1 })
            const hintText = new THREE.Mesh(hintTextGeometry, hintTextMaterial)
            hintText.position.set(hint.position.x, hint.position.y, hint.position.z)
            hintText.rotation.y = (hint.rotation / 90) * (Math.PI / 2)
            scene.add(hintText)
            hintObjects.set(hint.id, hintText)
          }
        })
      })

      /**
       * Textures
       */
      const atlasTexture = textureLoader.load(atlas)
      atlasTexture.encoding = THREE.sRGBEncoding

      const zeroTexture = textureLoader.load(binary0)
      const oneTexture = textureLoader.load(binary1)
      const twoTexture = textureLoader.load(binary2)

      const splatTexture = textureLoader.load(splat)

      /**
       * Materials
       */

      // Atlas
      const atlasMaterial = new THREE.MeshStandardMaterial({ map: atlasTexture })
      atlasTexture.flipY = false

      // Hologram
      const hologramMaterial = new THREE.MeshBasicMaterial({ wireframe: true, transparent: true, color: 0xed6b00, opacity: 0.5 })

      // Portal
      const portalMaterial = new THREE.ShaderMaterial({
        uniforms:
        {
          uTime: { value: 0 },
          uColorStart: { value: new THREE.Color(0xe72eff) },
          uColorEnd: { value: new THREE.Color(0xff4dff) }
        },
        vertexShader: portalVertexShader,
        fragmentShader: portalFragmentShader
      })

      /**
       * Animations
       */
      let mixer = null
      let penguinSquish = null

      /**
       * Update all materials
       */
      const updateAllMaterials = () => {
        scene.traverse((child) => {
          if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.material.envMapIntensity = 2.5
            child.material.needsUpdate = true
          }
        })
      }

      /**
       * Model 1
       */
      const holograms = []
      const hologramScale = 0.01
      let currentHologram = 1
      const buttons = []
      const focusables = []
      const blockers = []
      let headset
      gltfLoader.load(
        carouselGlb, (gltf) => {
          gltf.scene.traverse((child) => {
            switch (child.name) {
              case 'Teapot':
              case 'SM_Klein_Bottle':
              case 'SM_Starship':
              case 'Monkey':
              case 'Penguin_Hologram':
                child.visible = false
                child.scale.set(0, 0, 0)
                holograms.push(child)
                child.material = hologramMaterial
                break
              case 'Button_Inner_1':
              case 'Button_Inner_2':
              case 'Button_Inner_3':
              case 'Button_Inner_4':
                buttons.push(child)
                break
              case 'Penguin':
              case 'Lego_man':
              case 'Word_Cube':
              case 'Plant':
              case 'Hologram_projector':
              case 'Laptop':
              case 'Podium':
              case 'Terminal':
                focusables.push(child)
                child.material = atlasMaterial
                child.castShadow = true
                child.receiveShadow = true
                break
              case 'VR_Headset':
                headset = child
                focusables.push(child)
                child.material = atlasMaterial
                child.castShadow = true
                child.receiveShadow = true
                break
              case 'Carousel':
                blockers.push(child)
                child.material = atlasMaterial
                child.castShadow = true
                child.receiveShadow = true
                break
              case 'Tube':
                break
              case 'TV_Search' :
              case 'TV_Forward' :
              case 'TV_Back' :
                child.material = atlasMaterial
                child.castShadow = true
                child.receiveShadow = false
                focusables.push(child)
                break
              case 'TV_Divider_1' :
              case 'TV_Divider_2' :
                child.material = atlasMaterial
                child.castShadow = true
                child.receiveShadow = false
                break
              default:
                child.material = atlasMaterial
                child.castShadow = true
                child.receiveShadow = true
            }
          })

          mixer = new THREE.AnimationMixer(gltf.scene)
          penguinSquish = mixer.clipAction(gltf.animations[4])
          penguinSquish.loop = THREE.LoopOnce

          for (let index = 0; index < 4; index++) {
            mixer.clipAction(gltf.animations[index]).play()
          }

          scene.add(gltf.scene)
          updateAllMaterials()

          if (!isDesktop) {
            headset.position.y = headset.position.y + 0.1
            headset.lookAt(new THREE.Vector3(0.08, 1.4, -1.67468))

            // Laser
            const staticLaser = new Laser(headset.position, new THREE.Vector3(0.08, 1.4, -1.67468), 0xff1818, 0.5)
            scene.add(staticLaser)

            // Decal Geometry
            const orientation = new THREE.Euler(0, Math.PI / 2, 0, 'XYZ')
            const decalGeometry = new DecalGeometry(portalSurface, new THREE.Vector3(0.08, 1.4, -1.67468), orientation, new THREE.Vector3(1.5, 1.5, 1.5))

            // Decal Material
            const decalMaterial = new THREE.MeshBasicMaterial({
              map: splatTexture,
              color: 0xBF9E00,
              transparent: true,
              opacity: 0,
              depthTest: true,
              depthWrite: false,
              polygonOffset: true,
              polygonOffsetFactor: -4,
              wireframe: false
            })

            // Decal Mesh
            const decalMesh = new THREE.Mesh(decalGeometry, decalMaterial)
            scene.add(decalMesh)
            gsap.to(decalMesh.material, {
              opacity: 1,
              ease: 'power1.inOut',
              duration: 0.15
            })
          }
        }
      )

      let penguinGeometry
      gltfLoader.load(penguinGlb, (gltf) => {
        gltf.scene.traverse((child) => {
          penguinGeometry = child.geometry
        })
      })

      // gltfLoader.load(
      //   'portalcircle.glb', (gltf) => {
      //     gltf.scene.traverse((child) => {
      //       child.material = portalMaterial
      //     })

      //     gltf.scene.position.y = 0.3
      //     gltf.scene.position.x = 0.1
      //     gltf.scene.position.z = -1.68

      //     scene.add(gltf.scene)
      //     updateAllMaterials()
      //   }
      // )

      /**
       * Portal Surface
       */
      const portalSurfaceGeometry = new THREE.PlaneGeometry(3.2, 3.2, 1)
      const portalSurfaceMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00, side: THREE.DoubleSide, transparent: true, opacity: 0 })
      const portalSurface = new THREE.Mesh(portalSurfaceGeometry, portalSurfaceMaterial)
      portalSurface.position.set(0.08, 1.73, -1.68)
      portalSurface.rotation.y = Math.PI / 2
      portalSurface.name = 'portalSurface'
      scene.add(portalSurface)
      blockers.push(portalSurface)

      const portalDecals = []

      /**
       * Portal Laser
       */
      const Laser = function (start, end, colour, opacity) {
        const material = new THREE.LineBasicMaterial({
          color: colour,
          transparent: true,
          opacity: opacity
        })

        const laserPoints = []
        laserPoints.push(start)
        laserPoints.push(end)

        const geometry = new THREE.BufferGeometry().setFromPoints(laserPoints)

        const line = new THREE.Line(geometry, material)
        scene.add(line)
        return line
      }

      /**
       * Iframe Videos
       */
      const Element = function (id, url, x, y, z, ry) {
        const div = document.createElement('div')
        div.style.width = '1280px'
        div.style.height = '720px'
        div.style.backgroundColor = '#000'
        const iframe = document.createElement('iframe')
        iframe.id = id
        iframe.style.width = '1280px'
        iframe.style.height = '720px'
        iframe.style.border = '0px'
        iframe.src = url
        div.appendChild(iframe)
        const object = new CSS3DObject(div)
        object.position.set(x, y, z)
        object.rotation.y = ry
        return object
      }

      // Showreel
      const showreelChannels = [
        'https://player.vimeo.com/video/701315095?h=24169116eb&autoplay=1&loop=1&autopause=0'
        /*
        'https://player.vimeo.com/video/685847917?h=24a8543cc7&autoplay=1&loop=1&autopause=0',
        'https://player.vimeo.com/video/569309981?h=7431ebb744&autoplay=1&loop=1&autopause=0',
        'https://player.vimeo.com/video/703289983?h=de56663493&autoplay=1&loop=1&autopause=0'
         */
      ]
      let showreelCurrent = 0
      const showreel = new Element('showreel', 'https://player.vimeo.com/video/701315095?h=24169116eb&autoplay=1&loop=1&autopause=0', -1.6445, 2.087, -0.12, Math.PI)
      // const showreel = new Element('showreel', 'http://localhost:3000/', -1.6445, 2.087, -0.12, Math.PI)
      const showreelIframe = showreel.element.children[0]
      showreelIframe.title = 'Reel'
      const showreelPlayer = new Player(showreelIframe)
      showreelPlayer.setVolume(0)
      showreelPlayer.setLoop(true)
      showreel.scale.set(0.001115, 0.001115, 0.001115)
      showreel.visible = false
      if (isDesktop) cssScene.add(showreel)
      Player.onload = function () {
        showreelPlayer.play()
      }

      /**
       * Terminal
       */
      const terminal = document.getElementById('devTerminal')
      const devTerminal = new CSS3DObject(terminal)
      let penguinMode = false
      devTerminal.position.set(1.663, 2.29, 0.12)
      devTerminal.rotation.y = 2 * Math.PI
      devTerminal.scale.set(0.00278, 0.00278, 0.00278)
      window.$('#devTerminal').terminal({
        help: function () {
          this.echo('throughput [0-100] - change the binary tube throughput')
          this.echo('inception - open the Invidar website')
          this.echo('penguins - fill the office with penguins!')
          this.echo('...and more - try experimenting!')
        },
        dorsetcreative: function () {
          this.echo($('<img src="https://c.tenor.com/QiFdp8TPrV4AAAAd/cat-vomiting.gif" style="height: 400px">'))
        },
        inception: function () {
          this.echo($('<iframe>', {
            src: window.location.href,
            style: 'height: 500px; width: 900px'
          }))
        },
        throughput: function (amount) {
          gsap.to(particleEmitter, {
            chance: amount,
            ease: 'power1.inOut',
            duration: 4
          })
        },
        penguins: function () {
          penguinMode = true
          currentHologram = 5
          showHologram(5, true)
          showreelPlayer.loadVideo('https://player.vimeo.com/video/251187903?h=b456c4bbea&autoplay=1&loop=1&autopause=0')
          showreelPlayer.play()
        },
        happybirthday: function () {
          this.echo($('<img src="https://c.tenor.com/QiFdp8TPrV4AAAAd/cat-vomiting.gif" style="height: 400px">'))
        }
      },
      {
        greetings: false,
        onInit () {
          this.echo(() => '| |\\ \\ | | \\  /|  ⁄')
          this.echo(() => '| | \\ \\| | / / | |  Console v0.8')
          this.echo(() => 'type help for commands')
        }
      })
      devTerminal.visible = false
      cssScene.add(devTerminal)

      /**
       * Binary Particles
       */
      const zeroMaterial = new THREE.SpriteMaterial({
        map: zeroTexture
      })
      const oneMaterial = new THREE.SpriteMaterial({
        map: oneTexture
      })
      const twoMaterial = new THREE.SpriteMaterial({
        map: twoTexture
      })
      const particleMaterials = [
        zeroMaterial,
        oneMaterial,
        twoMaterial
      ]

      const BinaryParticle = function (x, y, z, particleMaterial, radius) {
        let particle
        if (penguinMode) {
          particle = new THREE.Mesh(penguinGeometry, atlasMaterial)
          particle.rotation.x = Math.random() * Math.PI * 2
          particle.rotation.y = Math.random() * Math.PI * 2
          particle.rotation.z = Math.random() * Math.PI * 2
        } else {
          particle = new THREE.Sprite(particleMaterial)
          particle.scale.set(0.13, 0.13, 1)
        }
        particle.position.x = x + ((Math.random() * radius) - radius / 2)
        particle.position.y = y + ((Math.random() * radius) - radius / 2)
        particle.position.z = z + ((Math.random() * radius) - radius / 2)
        scene.add(particle)
        return particle
      }

      /**
       * Sizes
       */
      const sizes = {
        width: window.visualViewport.width,
        height: window.visualViewport.height
      }

      window.addEventListener('resize', () => {
        // Update sizes
        sizes.width = (isBrowser) ? window.visualViewport.width : (window.matchMedia('(orientation: landscape)').matches) ? window.visualViewport.width : window.screen.width
        sizes.height = (isBrowser) ? window.visualViewport.height : (window.matchMedia('(orientation: landscape)').matches) ? window.visualViewport.height : window.screen.height
        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderers
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        rendererCSS.setSize(sizes.width, sizes.height)
        // rendererCSS.setPixelRatio(Math.min(window.devicePixelRatio, 2))
      })

      /**
       * Camera
       */
      // Base camera
      const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
      scene.add(camera)
      // const cameraHelper = new THREE.CameraHelper(camera)
      // scene.add(cameraHelper)

      // // Debug camera
      // const debugCamera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
      // debugCamera.position.x = 0
      // debugCamera.position.y = 15
      // debugCamera.position.z = 0
      // debugCamera.lookAt(new THREE.Vector3(0, 0, 0))
      // scene.add(debugCamera)

      // Controls
      // const controls = new OrbitControls(camera, canvas)
      // controls.enableDamping = true
      // controls.target = new THREE.Vector3(0, 2, 0)

      /**
       * Mouse
       */
      const mouse = new THREE.Vector2()
      let moving = false
      let headsetActive = false
      let currentLaser

      window.addEventListener('mousemove', (event) => {
        mouse.x = (event.clientX / sizes.width * 2) - 1
        mouse.y = -((event.clientY + window.pageYOffset) / sizes.height * 2) + 1
        if (intersects.length !== 0 && intersects[0].object.name !== 'Carousel') {
          canvas.style.cursor = 'pointer'
        } else {
          // return headset to default position
          canvas.style.cursor = 'default'
        }

        if (intersects.length !== 0 && intersects[0].object.name === 'portalSurface' && headset && isDesktop) {
          scene.remove(currentLaser)
          currentLaser = new Laser(headset.position, intersects[0].point, 0xff1818, 0.5)
          if (moving === false && headsetActive === false) {
            moving = true
            gsap.to(headset.position, {
              y: '+=0.1',
              ease: 'power1.inOut',
              duration: 0.4,
              onUpdate () {
              },
              onComplete () {
                moving = false
                headsetActive = true
              }
            })
          }
          headset.lookAt(intersects[0].point)
        } else {
          scene.remove(currentLaser)
          if (moving === false && headsetActive === true) {
            moving = true
            gsap.to(headset.position, {
              y: '-=0.1',
              ease: 'power1.inOut',
              duration: 0.4,
              onComplete () {
                moving = false
                headsetActive = false
                headset.lookAt(new THREE.Vector3(0.08, 1, -1.67468))
              }
            })
          }
          headset?.lookAt(new THREE.Vector3(0.08, 1, -1.67468))
        }
      })

      /**
       * Raycasting
       */
      // Create Raycaster
      const raycaster = new THREE.Raycaster()
      let intersects = []

      // Click Event Functions
      function pressButton (object, button) {
        const tl = gsap.timeline()
        tl.to(object.position, {
          x: 0.05,
          ease: 'power1.inOut',
          duration: 0.15,
          onComplete () {
            showHologram(button)
          }
        })
          .to(object.position, {
            x: 0,
            ease: 'power1.inOut',
            duration: 0.5
          })
      }
      function showHologram (number, instant) {
        busy = true
        holograms.forEach((hologram, index) => {
          if (index === number - 1) {
            hologram.visible = true
            const delay = ((instant) ? 0 : 0.5)
            gsap.to(hologram.scale, {
              x: hologramScale,
              y: hologramScale,
              z: hologramScale,
              ease: 'power1.inOut',
              duration: 0.75,
              delay: delay,
              onComplete () {
                busy = false
              }
            })
          } else {
            gsap.to(hologram.scale, {
              x: 0,
              y: 0,
              z: 0,
              ease: 'power1.inOut',
              duration: 0.75,
              onComplete () {
                hologram.visible = false
              }
            })
          }
        })
      }

      // Canvas Interaction Event
      canvas.addEventListener('click', (event) => {
        // 3D Button Interaction
        if (intersects.length !== 0 && !busy) {
          switch (intersects[0].object.name) {
            case 'Button_Inner_1':
              currentHologram = 1
              pressButton(intersects[0].object, currentHologram)
              removeHint('model_buttons')
              break
            case 'Button_Inner_2':
              currentHologram = 2
              pressButton(intersects[0].object, currentHologram)
              removeHint('model_buttons')
              break
            case 'Button_Inner_3':
              currentHologram = 3
              pressButton(intersects[0].object, currentHologram)
              removeHint('model_buttons')
              break
            case 'Button_Inner_4':
              currentHologram = 4
              pressButton(intersects[0].object, currentHologram)
              removeHint('model_buttons')
              break
            case 'TV_Search' :
              if (focussed) {
                unfocusCamera()
                gsap.to(showreel, {
                  volume: 0,
                  ease: 'power1.inOut',
                  duration: 1,
                  onUpdate () {
                    showreelPlayer.setVolume(showreel.volume)
                  }
                })
              } else {
                focusCamera(-1.65, 2.0, -1.35, -180, 0, -180)
                gsap.to(showreel, {
                  volume: 0.5,
                  ease: 'power1.inOut',
                  duration: 1,
                  onUpdate () {
                    showreelPlayer.setVolume(showreel.volume)
                  }
                })
              }
              removeHint('tv_buttons')
              break
            case 'TV_Forward':
              showreelNext()
              removeHint('tv_buttons')
              break
            case 'TV_Back':
              showreelPrev()
              removeHint('tv_buttons')
              break
            case 'Penguin':
              if (focussed) {
                penguinSquish.reset()
                penguinSquish.play()
              } else {
                focusCamera(3.5, 1.1, 1.2, 0, 80, 0)
              }
              break
            case 'Terminal':
              focusCamera(1.65, 2.2, 2.6, 0, 0, 0)
              break
            case 'Lego_man':
              focusCamera(-1, 1.9, -1, -180, -85, -180)
              break
            case 'Plant':
            case 'Word_Cube':
              focusCamera(-1, 1.6, -2.6, -180, -72, -180)
              break
            case 'Hologram_projector':
              focusCamera(-4.5, 1.2, 1.4, 0, -90, 0)
              break
            case 'Laptop':
              focusCamera(2.75, 1.2, -2, -180, 20, 180)
              break
            case 'VR_Headset':
            case 'Podium':
              focusCamera(4.25, 1.6, -1.5, -180, 90, 180)
              break
            case 'Carousel':
              if (focussed) {
                unfocusCamera()
              }
              break
            case 'portalSurface':
              if (!busy && isDesktop) {
                const hitPoint = intersects[0].point
                const hitObject = intersects[0].object

                // Random Color
                const color = new THREE.Color(0xffffff)
                color.setHex(Math.random() * 0xffffff)

                // Projectile
                const sphereGeometry = (penguinMode) ? penguinGeometry : new THREE.SphereGeometry(0.01, 10, 10)
                const sphereMaterial = (penguinMode) ? atlasMaterial : new THREE.MeshStandardMaterial({ color: color })
                const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial)
                sphereMesh.rotation.x = Math.random() * Math.PI * 2
                sphereMesh.rotation.y = Math.random() * Math.PI * 2
                sphereMesh.rotation.z = Math.random() * Math.PI * 2
                sphereMesh.position.set(1.508, 1.01, -1.62)
                sphereMesh.castShadow = true
                scene.add(sphereMesh)
                gsap.to(sphereMesh.position, {
                  x: intersects[0].point.x,
                  y: intersects[0].point.y,
                  z: intersects[0].point.z,
                  duration: 0.2,
                  onUpdate () {
                    if (!penguinMode) {
                      sphereMesh.scale.x = sphereMesh.scale.x + 1
                      sphereMesh.scale.y = sphereMesh.scale.y + 1
                      sphereMesh.scale.z = sphereMesh.scale.z + 1
                    }
                  },
                  onComplete () {
                    sphereMesh.visible = false

                    // Decal Geometry
                    const orientation = new THREE.Euler(0, Math.PI / 2, Math.random() * (Math.PI * 2), 'XYZ') // Z to rotate
                    const maxSize = 1.5
                    const minSize = 0.8
                    const decalSize = (Math.random() * (maxSize - minSize) + minSize)
                    const decalGeometry = new DecalGeometry(hitObject, hitPoint, orientation, new THREE.Vector3(decalSize, decalSize, decalSize))

                    // Decal Material
                    const decalMaterial = new THREE.MeshBasicMaterial({
                      map: splatTexture,
                      color: color,
                      transparent: true,
                      opacity: 0,
                      depthTest: true,
                      depthWrite: false,
                      polygonOffset: true,
                      polygonOffsetFactor: -4,
                      wireframe: false
                    })

                    // Decal Mesh
                    const maxDecals = 10
                    const decalMesh = new THREE.Mesh(decalGeometry, decalMaterial)
                    scene.add(decalMesh)
                    gsap.to(decalMesh.material, {
                      opacity: 1,
                      ease: 'power1.inOut',
                      duration: 0.3
                    })
                    portalDecals.push(decalMesh)

                    if (portalDecals.length > maxDecals) {
                      const removingDecal = portalDecals[portalDecals.length - (maxDecals + 1)]
                      gsap.to(removingDecal.material, {
                        opacity: 0,
                        ease: 'power1.inOut',
                        duration: 3,
                        onComplete () {
                          scene.remove(removingDecal)
                        }
                      })
                    }
                  }
                })
              }
              break
            default:
              if (focussed) {
                unfocusCamera()
              }
              break
          }
        } else {
          if (focussed) {
            unfocusCamera()
          }
        }
      })

      terminal.addEventListener('click', (event) => {
        if (!busy && !focussed) {
          focusCamera(1.65, 2.2, 2.6, 0, 0, 0)
        }
      })

      // showreelPlayer.on('pause', function () {
      //   if (cameraPosition.angle === 225) {
      //     if (!busy && !focussed) {
      //       showreelPlayer.play()
      //       focusCamera(-1.65, 2.0, -1.3, 0)
      //       gsap.to(showreel, {
      //         volume: 0.5,
      //         ease: 'power1.inOut',
      //         duration: 1,
      //         onUpdate () {
      //           showreelPlayer.setVolume(showreel.volume)
      //         }
      //       })
      //     }
      //   }
      // })

      // showreelPlayer.on('play', function () {
      //   if (cameraPosition.angle === 225) {
      //     if (!busy && !focussed) {
      //       showreelPlayer.pause()
      //       focusCamera(-1.65, 2.1, -1.3, 0)
      //       gsap.to(showreel, {
      //         volume: 0.5,
      //         ease: 'power1.inOut',
      //         duration: 1,
      //         onUpdate () {
      //           showreelPlayer.setVolume(showreel.volume)
      //         }
      //       })
      //     }
      //   }
      // })

      canvas.addEventListener('dblclick', (event) => {
        if (intersects.length !== 0) {
          switch (intersects[0].object.name) {
            case 'Button_Inner_1':
              currentHologram = 1
              pressButton(intersects[0].object, currentHologram)
              break
            default:
              break
          }
        }
      })

      /**
       * Renderer
       */
      const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true
      })
      renderer.setSize(sizes.width, sizes.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
      renderer.outputEncoding = THREE.sRGBEncoding

      // CSS Renderer
      const rendererCSS = new CSS3DRenderer()
      rendererCSS.setSize(sizes.width, sizes.height)
      rendererCSS.domElement.style.position = 'absolute'
      rendererCSS.domElement.style.top = 0
      rendererCSS.domElement.style.pointerEvents = 'none'
      container.appendChild(rendererCSS.domElement)

      // Shadows
      renderer.shadowMap.enabled = true
      renderer.shadowMap.type = THREE.PCFSoftShadowMap

      renderer.setClearColor('#181818')

      // debugObject.angle = 225
      // gui
      //   .add(debugObject, 'angle', 0, 360, 1)
      //   .onChange(() => {
      //     cameraToAngle(debugObject.angle)
      //   })

      /**
       * Camera Moving
       */
      // Setup Variables
      let currentPosition = 'invidar'
      let focussed = false
      const cameraReturnAngle = new THREE.Vector3()
      const cameraReturnPosition = new THREE.Vector3()
      const cameraPosition = { angle: 225 }

      // Functions

      // Camera Movement
      function angleLock () {
        if (cameraPosition.angle >= 360) {
          cameraPosition.angle -= 360
        } else if (cameraPosition.angle < 0) {
          cameraPosition.angle += 360
        }
      }
      function cameraToAngle (angle) {
        let distance = 8
        let height = 1.75
        let targetHeight = 1.5

        if (!isDesktop) {
          if (window.visualViewport.width <= 600) {
            if (window.visualViewport.width > window.visualViewport.height) {
              distance = 9
              height = 2
              targetHeight = 2
            } else {
              distance = 18
              height = 1
              targetHeight = 0
            }
          } else if (window.visualViewport.width <= 1024) {
            if (window.visualViewport.width > window.visualViewport.height) {
              distance = 9
              height = 2
              targetHeight = 2
            } else {
              distance = 14
              height = 1
              targetHeight = 1
            }
          } else if (window.visualViewport.width <= 1440) {
            distance = 12
            height = 2
            targetHeight = 2
          }
        }

        camera.position.x = Math.cos(angle / (180 / Math.PI)) * distance
        camera.position.z = Math.sin(angle / (180 / Math.PI)) * distance
        camera.position.y = height

        camera.lookAt(new THREE.Vector3(0, targetHeight, 0))
      }

      window.addEventListener('resize', (event) => {
        cameraToAngle(cameraPosition.angle)
      })

      function rotateCamera (startAngle, angleChange) {
        busy = true
        const tl = gsap.timeline()
        tl.to(cameraPosition, {
          angle: startAngle,
          ease: 'power1.inOut',
          duration: 0.2,
          onUpdate () {
            cameraToAngle(cameraPosition.angle)
            angleLock()
            checkScreenVisible()
          }
        })
          .to(cameraPosition, {
            angle: angleChange,
            ease: 'power1.inOut',
            duration: 1,
            onUpdate () {
              cameraToAngle(cameraPosition.angle)
              angleLock()
              checkScreenVisible()
            },
            onComplete () {
              angleLock()
              busy = false
            }
          })
      }

      // Drag Rotation
      let mouseDown = false
      let startMouseX = 0
      let oldMousex = 0

      canvas.addEventListener('mousedown', (event) => {
        if (!focussed && !busy) {
          busy = true
          mouseDown = true
          startMouseX = event.clientX
          oldMousex = event.clientX
        }
      })
      canvas.addEventListener('mousemove', (event) => {
        if (mouseDown) {
          if (event.clientX < oldMousex) {
            cameraPosition.angle += ((event.clientX - oldMousex) * 0.14)
          } else if (event.clientX > oldMousex) {
            cameraPosition.angle += ((event.clientX - oldMousex) * 0.14)
          }
          cameraToAngle(cameraPosition.angle)
          angleLock()
          checkScreenVisible()
          oldMousex = event.clientX
        }
      })
      canvas.addEventListener('mouseup', (event) => {
        if (mouseDown && startMouseX !== event.clientX) {
          angleLock()
          roomSnap()
        } else if (mouseDown) {
          busy = false
        }
        mouseDown = false
      })

      // Swipe Rotation
      let touchstartX = 0
      let touchendX = 0

      function handleGesture () {
        if (touchendX < touchstartX && (touchstartX - touchendX) > 10) { // Swiped Left
          if (!busy) {
            switch (currentPosition) {
              case 'model':
                showHologram(0)
                rotateCamera(135, '-=90')
                currentPosition = 'dev'
                break
              case 'dev':
                rotateCamera(45, '-=90')
                currentPosition = 'realtime'
                break
              case 'realtime':
                rotateCamera(315, '-=90')
                currentPosition = 'invidar'
                break
              case 'invidar':
                rotateCamera(225, '-=90')
                showHologram(currentHologram, true)
                currentPosition = 'model'
                break
              default:
                break
            }
          }
        } else if (touchendX > touchstartX && (touchendX - touchstartX) > 10) { // Swiped Right
          if (!busy) {
            switch (currentPosition) {
              case 'model':
                showHologram(0)
                rotateCamera(135, '+=90')
                currentPosition = 'invidar'
                break
              case 'dev':
                rotateCamera(45, '+=90')
                showHologram(currentHologram, true)
                currentPosition = 'model'
                break
              case 'realtime':
                rotateCamera(315, '+=90')
                currentPosition = 'dev'
                break
              case 'invidar':
                rotateCamera(225, '+=90')
                currentPosition = 'realtime'
                break
              default:
                break
            }
          }
        }
      }
      canvas.addEventListener('touchstart', e => {
        // Laser Pointing

        touchstartX = e.changedTouches[0].screenX
      })
      canvas.addEventListener('touchend', e => {
        touchendX = e.changedTouches[0].screenX
        handleGesture()
      })

      // Snap to nearest room
      function roomSnap () {
        busy = true
        const nearestAngle = ((Math.ceil(cameraPosition.angle / 90) * 90) - 45)

        // Update room state
        switch (nearestAngle) {
          case 45:
            currentPosition = 'dev'
            showHologram(0)
            break
          case 135:
            currentPosition = 'model'
            showHologram(currentHologram, true)
            break
          case 225:
            currentPosition = 'invidar'
            showHologram(0)
            break
          case 315:
            currentPosition = 'realtime'
            showHologram(0)
            break
          default:
            break
        }

        // Rotate camera to the nearest room
        gsap.to(cameraPosition, {
          angle: nearestAngle,
          ease: 'power1.inOut',
          duration: 0.4,
          onUpdate () {
            cameraToAngle(cameraPosition.angle)
            angleLock()
            checkScreenVisible()
          },
          onComplete () {
            angleLock()
            busy = false
          }
        })
      }

      // IFrame visibility loading zones
      function checkScreenVisible () {
        if (cameraPosition.angle >= 180 && cameraPosition.angle <= 270) {
          showScreen(showreel, showreelPlayer)
        } else {
          hideScreen(showreel, showreelPlayer)
        }

        if (cameraPosition.angle >= 0 && cameraPosition.angle <= 90) {
          showScreen(devTerminal)
        } else {
          hideScreen(devTerminal)
        }
      }

      function showreelNext () {
        showreelCurrent++
        if (showreelCurrent >= showreelChannels.length) {
          showreelCurrent = 0
        }
        showreelPlayer.loadVideo(showreelChannels[showreelCurrent])
      }

      function showreelPrev () {
        showreelCurrent--
        if (showreelCurrent < 0) {
          showreelCurrent = showreelChannels.length - 1
        }
        showreelPlayer.loadVideo(showreelChannels[showreelCurrent])
      }

      function showScreen (screen, player) {
        screen.visible = true
        if (player) {
          player.play()
        }
      }
      function hideScreen (screen, player) {
        screen.visible = false
        if (player) {
          player.pause()
        }
      }

      function removeHint (id) {
        const hintObject = hintObjects.get(id)
        gsap.to(hintObject.material, {
          opacity: 0,
          ease: 'power1.inOut',
          duration: 1,
          onComplete () {
            // scene.remove(hintObject)
          }
        })
      }

      /**
       * HTML HUD fading
       */
      const htmlElements = {}
      htmlElements.opacity = 0
      function fadeHud (visible) {
        if (visible) {
          // Fade in HTML Elements
          gsap.to(htmlElements, {
            opacity: 1,
            ease: 'power1.inOut',
            duration: 0.75,
            onUpdate () {
              htmlArray.forEach(element => {
                element.style.opacity = htmlElements.opacity
              })
            },
            onComplete () {
              htmlArray.forEach(element => {
                if (element.id !== 'tagline') {
                  element.style.pointerEvents = 'auto'
                }
              })
            }
          })
        } else {
          // Fade out HTML Elements
          gsap.to(htmlElements, {
            opacity: 0,
            ease: 'power1.inOut',
            duration: 0.75,
            onUpdate () {
              htmlArray.forEach(element => {
                element.style.opacity = htmlElements.opacity
              })
            },
            onComplete () {
              htmlArray.forEach(element => {
                element.style.pointerEvents = 'none'
              })
            }
          })
        }
      }

      // Camera Focussing
      function focusCamera (x, y, z, anglex, angley, anglez) {
        if (!isDesktop) {
          return false
        }
        busy = true
        if (!focussed) {
          cameraReturnPosition.x = camera.position.x
          cameraReturnPosition.y = camera.position.y
          cameraReturnPosition.z = camera.position.z
          cameraReturnAngle.x = camera.rotation.x
          cameraReturnAngle.y = camera.rotation.y
          cameraReturnAngle.z = camera.rotation.z
        }
        fadeHud(false)
        gsap.to(camera.position, {
          x: x,
          y: y,
          z: z,
          ease: 'power1.inOut',
          duration: 0.75
        })
        gsap.to(camera.rotation, {
          x: anglex / (180 / Math.PI),
          y: angley / (180 / Math.PI),
          z: anglez / (180 / Math.PI),
          ease: 'power1.inOut',
          duration: 0.75,
          onComplete () {
            busy = false
          }
        })
        focussed = true
      }

      function unfocusCamera () {
        busy = true
        fadeHud(true)
        gsap.to(showreel, {
          volume: 0,
          ease: 'power1.inOut',
          duration: 0.75,
          onUpdate () {
            showreelPlayer.setVolume(showreel.volume)
          }
        })
        gsap.to(camera.position, {
          x: cameraReturnPosition.x,
          y: cameraReturnPosition.y,
          z: cameraReturnPosition.z,
          ease: 'power1.inOut',
          duration: 0.75
        })
        gsap.to(camera.rotation, {
          x: cameraReturnAngle.x,
          y: cameraReturnAngle.y,
          z: cameraReturnAngle.z,
          ease: 'power1.inOut',
          duration: 0.75,
          onComplete () {
            busy = false
          }
        })
        focussed = false
      }

      // Initial Position
      cameraToAngle(cameraPosition.angle)

      // invidarButtonMobile.addEventListener('click', (event) => {
      //   if (!busy) {
      //     switch (currentPosition) {
      //       case 'model':
      //         showHologram(0)
      //         rotateCamera(135, '+=90')
      //         break
      //       case 'dev':
      //         rotateCamera(45, '+=180')
      //         break
      //       case 'realtime':
      //         rotateCamera(315, '-=90')
      //         break
      //       default:
      //         break
      //     }
      //     currentPosition = 'invidar'
      //   }
      // })
      invidarButton.addEventListener('click', (event) => {
        if (!busy) {
          switch (currentPosition) {
            case 'model':
              showHologram(0)
              rotateCamera(135, '+=90')
              break
            case 'dev':
              rotateCamera(45, '+=180')
              break
            case 'realtime':
              rotateCamera(315, '-=90')
              break
            default:
              break
          }
          currentPosition = 'invidar'
        }
      })
      modelButton.addEventListener('click', (event) => {
        if (!busy) {
          showHologram(currentHologram)
          switch (currentPosition) {
            case 'invidar':
              rotateCamera(225, '-=90')
              break
            case 'dev':
              rotateCamera(45, '+=90')
              break
            case 'realtime':
              rotateCamera(315, '+=180')
              break
            default:
              break
          }
          currentPosition = 'model'
        }
      })
      devButton.addEventListener('click', (event) => {
        if (!busy) {
          switch (currentPosition) {
            case 'model':
              showHologram(0)
              rotateCamera(135, '-=90')
              break
            case 'invidar':
              rotateCamera(225, '-=180')
              break
            case 'realtime':
              rotateCamera(315, '+=90')
              break
            default:
              break
          }
          currentPosition = 'dev'
        }
      })
      realtimeButton.addEventListener('click', (event) => {
        if (!busy) {
          switch (currentPosition) {
            case 'model':
              showHologram(0)
              rotateCamera(135, '-=180')
              break
            case 'dev':
              rotateCamera(45, '-=90')
              break
            case 'invidar':
              rotateCamera(225, '+=90')
              break
            default:
              break
          }
          currentPosition = 'realtime'
        }
      })

      /**
       * Lights
       */
      const ambientLight = new THREE.AmbientLight('#ffffff', 0.5)
      scene.add(ambientLight)

      const directionalLight = new THREE.DirectionalLight('#ffffff', 1)
      directionalLight.position.set(0, 20, 0)
      directionalLight.castShadow = true
      scene.add(directionalLight)

      // Shadows
      directionalLight.shadow.mapSize.width = 512
      directionalLight.shadow.mapSize.height = 512
      directionalLight.shadow.camera.near = 0.5
      directionalLight.shadow.camera.far = 500

      /**
       * Animate
       */
      const clock = new THREE.Clock()
      let previousTime = 0

      // Particles
      const particles = []
      const particleEmitter = {}
      particleEmitter.particleLifeExpectancy = 1.1
      particleEmitter.particleXdistance = 1.8
      particleEmitter.particleYdistance = 2.1
      particleEmitter.chance = 15 // Percentage chance per frame
      particleEmitter.radius = 0.3 // Radius from origin point that particles can spawn

      // gui.add(particleEmitter, 'particleLifeExpectancy', 0, 20, 0.05)
      // gui.add(particleEmitter, 'particleXdistance', 0, 20, 0.05)
      // gui.add(particleEmitter, 'particleYdistance', 0, 20, 0.05)

      const tick = () => {
        const elapsedTime = clock.getElapsedTime()
        const deltaTime = elapsedTime - previousTime
        previousTime = elapsedTime

        // Update controls
        // controls.update()

        // Cast Ray
        raycaster.setFromCamera(mouse, camera)
        const intersectables = blockers.concat(buttons, focusables)
        intersects = raycaster.intersectObjects(intersectables)

        // Update Mixer
        if (mixer !== null) {
          mixer.update(deltaTime)
        }

        // Animate Holograms
        holograms.forEach(hologram => {
          hologram.position.y = 1.25 + Math.sin(elapsedTime * 1.3) * 0.1
          hologram.rotation.z = (elapsedTime * 1.25)
        })

        // Animate Portal
        portalMaterial.uniforms.uTime.value = elapsedTime

        // Animate Binary Particles
        const rand = Math.random()
        let materialIndex = 0
        const particleStart = new THREE.Vector3(0.1, 1.35, 0.5)
        if (rand <= (particleEmitter.chance / 100)) {
          const binaryRand = Math.random()
          if (binaryRand <= 0.5) {
            materialIndex = 0
          } else if (binaryRand <= 0.995) {
            materialIndex = 1
          } else {
            materialIndex = 2
          }
          const particle = new BinaryParticle(particleStart.x, particleStart.y, particleStart.z, particleMaterials[materialIndex], particleEmitter.radius)
          particle.birth = elapsedTime
          particles.push(particle)
        }
        particles.forEach(particle => {
          const particleLifespan = elapsedTime - particle.birth
          if (particleLifespan >= particleEmitter.particleLifeExpectancy) {
            scene.remove(particle)
          } else {
            particle.position.x = particle.position.x + ((particleEmitter.particleXdistance * deltaTime) / particleEmitter.particleLifeExpectancy) * (particleEmitter.particleLifeExpectancy - particleLifespan)
            particle.position.y = particle.position.y - ((particleEmitter.particleYdistance * deltaTime) / particleEmitter.particleLifeExpectancy) * particleLifespan
            if (penguinMode) {
              particle.rotation.x += 0.01
              particle.rotation.y += 0.02
              particle.rotation.z += 0.01
            }
          }
        })

        // Render
        renderer.render(scene, camera)
        rendererCSS.render(cssScene, camera)

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
      }

      tick()
    }
  }, [height, width])

  return (
    <>
      <div id='landingFront' className='h-screen w-full top-0 relative' style={{ minHeight: height, minWidth: width, height: height, width: width }}>
        <div>
          <canvas className='webgl' />
          <div id='devTerminal' className='bg-black' style={{ width: '820px', height: '480px' }} />
          <div className='cssContainer' />
        </div>
        <div id='tagline' className='pointer-events-none font-bold text-white text-strapline lg:text-strapline 3xl:text-strapline bottom-20 md:bottom-14 lg:bottom-16 left-8 md:left-16 2xl:left-44 absolute z-50 opacity-0'>
          <div className='relative'>
            <span className='absolute -bottom-4 -ml-5 3xl:-ml-12 3xl:h-32 3xl:w-32 h-32 w-32 border-b-4 border-l-4 border-invidarLight' />
            <p className={((width < 768) ? 'strapline-mini' : (window.matchMedia('(orientation: landscape)').matches && !isBrowser && (width < 1024)) ? ' strapline-mini ' : ' strapline md:w-60 ') + ' w-32 font-thin'}>{t('app.inventingrealities')}</p>
          </div>
        </div>
        <div className='pointer-events-none font-bold text-white text-strapline lg:text-strapline 3xl:text-strapline bottom-16 md:bottom-9 lg:bottom-12 right-4 md:right-16 2xl:right-44 absolute z-50 text-right'>
          <div className='flex flex-col'>
            <h2
              id='invidarButton' className={((width < 768)
                ? 'strapline-mini'
                : (window.matchMedia('(orientation: landscape)').matches && !isBrowser && (width < 1024)) ? ' strapline-mini ' : ' strapline ') +
                ' text-invidarLight uppercase text-2xl font-normal mb-3 hover:text-invidarModelling cursor-pointer pointer-events-auto opacity-0 unselectable'}
            >INVIDAR
            </h2>
            <h2
              id='modelButton' className={((width < 768)
                ? 'strapline-mini'
                : (window.matchMedia('(orientation: landscape)').matches && !isBrowser && (width < 1024)) ? ' strapline-mini ' : ' strapline ') +
                ' text-invidarLight uppercase text-2xl font-normal my-3 hover:text-invidarModelling cursor-pointer pointer-events-auto opacity-0 unselectable'}
            >{t('app.modelling')}
            </h2>
            <h2
              id='devButton' className={((width < 768)
                ? 'strapline-mini'
                : (window.matchMedia('(orientation: landscape)').matches && !isBrowser && (width < 1024)) ? ' strapline-mini ' : ' strapline ') +
                ' text-invidarLight uppercase text-2xl font-normal my-3 hover:text-invidarDevelopment cursor-pointer pointer-events-auto opacity-0 unselectable'}
            >{t('app.development')}
            </h2>
            <h2
              id='realtimeButton' className={((width < 768)
                ? 'strapline-mini'
                : (window.matchMedia('(orientation: landscape)').matches && !isBrowser && (width < 1024)) ? ' strapline-mini ' : ' strapline ') +
                ' text-invidarLight uppercase text-2xl font-normal mt-3 hover:text-invidarRealtime cursor-pointer pointer-events-auto opacity-0 unselectable'}
            >{t('app.realtime')}
            </h2>
          </div>
        </div>
        <img id='invidarLoading' alt='logo-animated' src={loadGif} className='absolute w-full md:w-86 lg:w-1/2 3xl:w-1/2 top-2/5 left-0 right-0 lg:left-0 mx-auto z-40 cursor-pointer pointer-events-none' />
      </div>
    </>
  )
}
