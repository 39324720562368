import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import modellingImage from '../img/services/modellingcolour.png'
import developmentImage from '../img/services/developmentcolour.png'
import realtimeImage from '../img/services/realtimecolour.png'
import modellingBackground from '../img/services/modellingbg.webp'
import developmentBackground from '../img/services/developmentbg.webp'
import realtimeBackground from '../img/services/realtimebg.webp'

export const ServiceLayout = (props) => {
  const [t] = useTranslation('global')

  let image
  let video
  let subheader
  let text
  let listheader
  let bullets
  let background

  switch (props.service) {
    case 'modelling':
      image = modellingImage
      video = 'https://player.vimeo.com/video/745691488?h=a05576cf6f&background=1&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1'
      background = modellingBackground
      subheader = t('services.modelling.subheader')
      text = t('services.modelling.text')
      listheader = t('services.modelling.listheader')
      bullets = t('services.modelling.bullets')
      break
    case 'realtime':
      image = realtimeImage
      video = 'https://player.vimeo.com/video/745691730?h=e05b4c4ec4&background=1&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1'
      background = realtimeBackground
      subheader = t('services.realtime.subheader')
      text = t('services.realtime.text')
      listheader = t('services.realtime.listheader')
      bullets = t('services.realtime.bullets')
      break
    case 'development':
      image = developmentImage
      video = 'https://player.vimeo.com/video/745691114?h=e8fde81fe8&background=1&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1'
      background = developmentBackground
      subheader = t('services.development.subheader')
      text = t('services.development.text')
      listheader = t('services.development.listheader')
      bullets = t('services.development.bullets')
      break
    default:
      break
  }

  return (
    <>
      <div className='flex h-screen bg-invidarDark'>
        <div id='videoBackgroundWrapper' className='hidden lg:block absolute top-0 left-0 w-full h-full pointer-events-none overflow-hidden z-0 bg-invidarDark opacity-60' allow='autoplay; fullscreen;'>
          <iframe title='Service Video Background' className='w-full center-fully video-background-iframe' src={video} allow='autoplay; fullscreen; picture-in-picture' />
        </div>
        <div className='absolute top-0 left-0 w-screen h-screen lg:hidden bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${background})` }} />
        <div className='service-background flex w-full lg:w-5/12 h-full z-10 justify-center overflow-scroll lg:overflow-hidden'>
          <Link to='/services'>
            <p className='services-back fixed top-22 left-6 lg:top-12 lg:left-36 text-white font-menlo'>{t('app.servicesback')}</p>
          </Link>
          <div className='flex justify-between flex-col w-10/12 lg:8/12 xl:w-5/12 h-fit my-auto pt-32 lg:pt-16 pb-16 text-left text-white'>
            <div>
              <img className='service-content-title w-52 ' alt={props.service} src={image} />
              <p className='text-lg mt-6 uppercase text-invidarPrimary'>{subheader}</p>
            </div>
            <div className='my-4 lg:my-6'>
              <p className='text-md mb-4 lg:mb-8 text-left whitespace-pre-line'>{text}</p>
              {/* <hr /> */}
              <div className='text-md mt-4 lg:mt-8'>
                <p className='mb-4 text-left whitespace-pre-line uppercase text-invidarPrimary'>{listheader}</p>
                <p className='text-left whitespace-pre-line leading-loose'>{bullets}</p>
              </div>
            </div>
            <button onClick={props.setShowContact} className='invidar-about-button relative text-md font-thin border-2 border-invidarPrimary w-fit '>
              <div className='invidar-about-button-bar absolute top-0 bottom-0 h-full bg-invidarPrimary' />
              <p className='mix-blend-difference text-white text-xl'>{t('app.contactus')}</p>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
