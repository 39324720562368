import React from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'

import Player from '@vimeo/player'

import modelling from '../img/Landing/modelling.png'
import development from '../img/Landing/development.png'
import realtime from '../img/Landing/realtime.png'
import background from '../img/Landing/background.webp'

export const InvidarLandingVideoNew = () => {
  $(function () {
    const videoBackground = $('#videoBackground')
    console.log(videoBackground)
    const videoBackgroundPlayer = new Player(videoBackground)
    const landing = $('#landing')
    landing.on('click', function () {
      videoBackgroundPlayer.pause()
      console.log('UNMUTE BITCHES')
    })
  })

  return (
    <div id='landing' className='relative bg-invidarDark flex justify-center lg:overflow-hidden min-w-screen min-h-screen'>
      <div id='videoBackgroundWrapper' className='hidden lg:block absolute top-0 left-0 w-full h-full pointer-events-none overflow-hidden z-0' allow='autoplay; fullscreen;'>
        <iframe id='videoBackground' title='Homepage Video Background' className='w-full center-fully video-background-iframe' src='https://player.vimeo.com/video/744927687?h=2e5b4f11e8&background=1&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1' allow='autoplay; fullscreen; picture-in-picture' />
      </div>
      <div className='w-screen h-screen lg:hidden bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${background})` }}>
        <div className='w-screen h-screen bg-black opacity-40' />
      </div>
      {/* <div className='absolute w-1/2 h-full right-0' style={{ background: 'linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.15) 20%, rgba(0,0,0,0.05) 30%, rgba(0,0,0,0.05) 80%, rgba(0,0,0,0.15) 85%, rgba(0,0,0,0.8) 100%)' }} /> */}
      {/* <div className='absolute w-1/2 h-full left-0' style={{ background: 'rgba(0,0,0,0.3)' }} /> */}
      <div className='absolute h-full w-full left-0' style={{ background: 'rgba(0,0,0,0.3)' }} />
      {/* <div className='absolute z-0 bg-white bottom-0 w-full h-64'></div> */}
      <div id='brands' className='absolute flex flex-col lg:flex-row justify-around w-full lg:w-9/12 bottom-4 lg:bottom-10'>
        <div id='modelling' className='flex flex-col items-center relative w-full'>
          <div className='flex justify-start lg:justify-center items-center py-5 px-4 sm:px-8 transform transition-all duration-300 w-full'>
            <Link id='modellingImage' to='/modelling'>
              <img className='h-9' alt='modelling' src={modelling} />
            </Link>
          </div>
        </div>
        <div id='realtime' className='flex flex-col items-center relative w-full'>
          <div className='flex justify-start lg:justify-center items-center py-5 px-4 sm:px-8 transform transition-all duration-300 w-full'>
            <Link id='modellingImage' to='/realtime'>
              <img className='h-9' alt='realtime' src={realtime} />
            </Link>
          </div>
        </div>
        <div id='development' className='flex flex-col items-center relative w-full'>
          <div className='flex justify-start lg:justify-center items-center py-5 px-4 sm:px-8 transform transition-all duration-300 w-full'>
            <Link id='modellingImage' to='/development'>
              <img className='h-9' alt='development' src={development} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
