import React, { useEffect, useState } from 'react'
import { AboutPartners } from '../Components/AboutPartners'
import api from '../js/api'

export const About = ({ setShowContact }) => {
  const [data, setData]  = useState(null)
  useEffect(() => {
    async function fetchData () {
      const result = await api.Pages.aboutUs()
      if (result.success) {
        setData(result.data)
      }
    }
    fetchData()
  }, [])
  return (
    <div className='content'>
      <AboutPartners data={data} setShowContact={setShowContact} />
    </div>
  )
}
