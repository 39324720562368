import React from 'react'
import { useLocation } from 'react-router-dom'
import { InvidarLandingVideoNew } from './InvidarLandingNew'
export const LandingFront = (props) => {
  const location = useLocation()

  return (
    <>
      {(location.pathname === '/' || props.showLandingAnimation) &&
        <div id='landingFront' className='h-full w-full top-0 relative'>
          <InvidarLandingVideoNew />
        </div>}
    </>
  )
}
