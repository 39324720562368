import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, Link } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import ReactGA from 'react-ga'

import { About } from './About'
import { ContactPopUp } from '../Components/ContactPopUp'
import { DemosPage } from './DemosPage'
import Footer from '../Components/Footer'
import Sidebar from '../Components/Sidebar'
import { LandingFront } from '../Components/LandingFront'
import { PixelStream } from '../Components/PixelStream'
import { Carousel } from './Carousel'
import { Services } from './Services'
import { Modelling } from './Modelling'
import { Realtime } from './Realtime'
import { Development } from './Development'
import { Contact } from './Contact'
import { Privacy } from './Privacy'
import { Terms } from './Terms'

import { useProjects } from '../js/projectsList'

import { MetaTagsComponent } from '../Components/MetaTagsComponent'

export const AppLayout = () => {
  const [showContact, setShowContact] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [setSelectedProjectIframe] = useState('')
  const location = useLocation()
  const projects = useProjects()

  useEffect(() => {
    setTimeout(() => {
      ReactGA.set({ page: location?.pathname })
      ReactGA.pageview(location.pathname)
    }, 500)
  }, [location.pathname])

  return (
    <>
      <MetaTagsComponent />
      <ContactPopUp setShowContact={setShowContact} showContact={showContact} />
      {/* INVIDAR LOGOS */}
      <div className={(location.pathname !== '/' ? 'mix-blend-difference' : '') + ' fixed top-11 right-4 sm:right-8 lg:right-8 z-20 h-10 lg:h-14'}>
        <Link aria-label='Invidar' to='/'>
          <svg id='invidarSVG' className={(location.pathname === '/' || showMenu ? 'h-8 lg:h-14' : 'h-8') + ' transition-all ease-in-out duration-500'} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 325.32 46.85' fill='white'><rect y='0.01' width='6.81' height='46.79' /><rect x='151.91' y='0.01' width='6.81' height='46.79' /><polygon points='80.52 46.81 45.51 0.01 38.7 0.01 38.7 46.8 45.51 46.8 45.51 11.6 72.02 46.79 80.52 46.81' /><polygon points='230.42 46.81 265.43 0.01 272.24 0.01 272.24 46.8 265.43 46.8 265.43 11.6 238.92 46.79 230.42 46.81' /><polygon points='118.59 0.01 118.58 35.23 92.07 0.03 83.57 0.01 118.58 46.81 125.39 46.81 125.39 0.01 118.59 0.01' /><polygon points='219.07 23.41 195.71 0.05 186.09 0.05 209.46 23.42 186.09 46.79 195.71 46.79 219.07 23.43 219.04 23.41 219.07 23.41' /><polygon points='325.32 0 315.11 0 299.3 15.91 299.3 23.34 299.3 26.29 299.3 46.85 306.86 46.85 306.86 18.44 325.32 0' /></svg>
        </Link>
      </div>
      <Sidebar setShowContact={setShowContact} setShowMenu={setShowMenu} showMenu={showMenu} />
      <TransitionGroup className='h-full w-full overflow-x-hidden min-h-screen'>
        <CSSTransition in classNames='render' timeout={1000} unmountOnExit key={location.pathname}>
          <div className='h-full w-full z-10 relative overflow-y-hidden'>
            <Routes>
              <Route path='/' element={<LandingFront />} />
              <Route path='/about' element={<About setShowContact={setShowContact} />} />
              <Route path='/contact' element={<Contact setShowContact={setShowContact} />} />

              <Route path='/services' element={<Services setShowContact={setShowContact} />} />
              <Route path='/modelling' element={<Modelling setShowContact={setShowContact} />} />
              <Route path='/realtime' element={<Realtime setShowContact={setShowContact} />} />
              <Route path='/development' element={<Development setShowContact={setShowContact} />} />
              {
                /*
                  <Route path='/demos' element={<DemosPage setSelectedProjectIframe={setSelectedProjectIframe} />} />
                  <Route path='/demos/office' element={<PixelStream project={projects.find(project => project.slug === 'office')} />} />
                  <Route path='/demos/apartment' element={<PixelStream project={projects.find(project => project.slug === 'apartment')} />} />
                  <Route path='/demos/botany' element={<PixelStream project={projects.find(project => project.slug === 'botany')} />} />
                  <Route path='/demos/carousel' element={<Carousel />} />
                 */
              }
              <Route path='/privacy' element={<Privacy setShowContact={setShowContact} />} />
              <Route path='/terms' element={<Terms setShowContact={setShowContact} />} />
            </Routes>
          </div>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </>
  )
}
